<template>
  <div class="dlg-forget-container">
    <div class="form-box">
      <div class="title">忘记密码</div>
      <a-form-model ref="refForm" :model="form" :rules="rules">
        <div class="tab-row row-phone">
          <a-form-model-item prop="area">
            <a-select v-model="form.area"
                      class="area"
                      @change="handleAreaChange">
              <a-select-option value="+86">+86</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="mobilePhone">
            <a-input v-model="form.mobilePhone"
                     :maxLength="11"
                     class="phone"
                     placeholder="注册时填写的手机号">
            </a-input>
          </a-form-model-item>
        </div>
        <div class="tab-row row-smsCode">
          <a-form-model-item prop="smsCode">
            <a-input v-model="form.smsCode"
                     :maxLength="6"
                     class="smsCode"
                     placeholder="手机验证码">
            </a-input>
          </a-form-model-item>
          <a-form-model-item>
            <CountDown class="send" @sendClick="handleSendMsg"/>
          </a-form-model-item>
        </div>
        <div class="tab-row row-password">
          <a-form-model-item prop="password">
            <a-input v-model="form.password"
                     type="password"
                     class="password"

                     :readOnly="readonly"
                     @blur="readonly = true"
                     @focus.native="readonly = false"
                     @click.native="readonly = false"
                     placeholder="重设密码8-16位">
            </a-input>
          </a-form-model-item>
        </div>
        <div class="tab-row">
          <a-form-model-item>
            <div class="protocol">已有账号？<a @click="handleJump">登录</a></div>
          </a-form-model-item>
        </div>
        <div class="row-button">
          <a-button type="primary" class="btn-ok" :loading="loading" @click="handleSubmitForm()">确认修改</a-button>
        </div>
      </a-form-model>
    </div>
  </div>
</template>

<script>
  import {validateNewPass, validateUser} from './loginUtil.js'
  import * as LoginApi from '@/api/auth.api.js'
  import * as Encryptor from '@/utils/encryptor'
  import CountDown from "@/components/CountDown";

  export default {
    name: 'PasswordForget',
    components: {CountDown},
    data() {
      return {
        readonly: true,
        form: {
          area: '+86',
          mobilePhone: null,
          password: null,
          smsCodeKey: null,
          smsCode: null,
        },
        redirect: null,
        loading: false,
        rules: {
          mobilePhone: [
            {validator: validateUser, trigger: 'blur'}
          ],
          password: [
            {validator: validateNewPass, trigger: 'blur'}
          ],
          smsCode: [
            {required: true, message: '手机验证码必须输入'}
          ],
        }
      };
    },
    methods: {
      /**
       * 区号变更
       */
      handleAreaChange(val) {
      },

      /**
       * 关闭当前
       */
      handleJump() {
        this.$emit('handleOk')
      },

      /**
       * 修改密码
       */
      handleSubmitForm() {
        this.$refs["refForm"].validate(valid => {
          if (valid) {
            this.loading = true;
            LoginApi.modifyPwd({
              area: this.form.area,
              phone: this.form.mobilePhone,
              code: this.form.smsCode,
              smsCodeKey: this.form.smsCodeKey,
              // password: Encryptor.encrypt(this.form.password),
              newPassword: Encryptor.encrypt(this.form.password),
            }).then(() => {
              this.loading = false;
              this.$modal.msg("修改密码成功，请重新登录")
              this.$emit('handleOk')
              // window.location.href = "/login"
            }).catch(err => {
              this.loading = false;
              console.error(err)
            });
          }
        });
      },

      // 发送验证码
      handleSendMsg(params, callback) {
        this.$refs["refForm"].validateField(['mobilePhone'], (errorMessage) => {
          if (errorMessage) {
            console.log(errorMessage)
            return;
          } else {
            LoginApi.sendSmsCode({
              area: this.form.area,
              phone: this.form.mobilePhone,
            }).then(data => {
              // this.form.smsCodeKey = data.smsCodeKey;
              callback()
            })
          }
        })
      },
    },
  };
</script>

<style scoped lang="scss">
  .dlg-forget-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .form-box {
    width: 270px;
    margin-top: 84px;

    .title{
      color: #1E1E1E;
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 23px;
    }
    .tab-row {
      display: flex;
    }
  }

  .row-phone {
    .area {
      width: 81px;
      height: 38px !important;
      margin-right: 12px;
    }

    .phone {
      width: 170px;
    }
  }

  .row-smsCode {
    margin-top: -5px;
    .smsCode {
      width: 140px;
      height: 38px !important;
    }

    .send {
      margin-left: 8px;
      width: 115px;
      height: 39px !important;
      border-radius: 2px;
      border-color: #1E1E1E;
      background-color: #1E1E1E;
    }
  }

  .row-password {
    margin-top: -5px;
    .password {
      width: 263px;
    }

  }

  .protocol {
    height: 22px;
    line-height: 22px;
    font-weight: 400;
    font-size: 11px;
    margin-top: -10px;

    a {
      color: #1E1E1E;
      text-decoration: underline;
    }
  }

  .row-button {
    height: 39px;
    color: #1E1E1E;
    text-align: center;
    margin-top: 32px;

    .btn-ok {
      font-weight: 500;
      font-size: 12px;
      width: 150px;
      height: 39px;
      border-radius: 2px;
      border-color: #1E1E1E;
      background-color: #1E1E1E;
    }

  }
</style>
