<!-- //
字典码值转换
用法三种类型，如果raw.dictStyle优先级高于type
<dict-tag :options="{type: 'span'}" :data="dict.type.EmployeeStatus" :value="text"></dict-tag>
<dict-tag :options="{type: 'tag'}" :data="dict.type.EmployeeStatus" :value="text"></dict-tag>
<dict-tag :options="{type: 'badge'}" :data="dict.type.EmployeeStatus" :value="text"></dict-tag>
<dict-tag :data="dict.type.EmployeeStatus" :value="text"></dict-tag>
@auth xuyd
@date 2022-11-01
//-->
<template>
  <div>
    <template v-for="(value, index) in values">
      <template v-if="mapData[value]">
        <template v-if="mapData[value].raw && mapData[value].raw.dictStyle">
          <a-tag v-if="opts.type === 'tag'"
                 :type="mapData[value].raw.dictStyle"
                 :key="index">
            {{ mapData[value][opts.labelField] }}{{opts.suffix}}
          </a-tag>
          <span v-else-if="opts.type === 'span'" :key="value" :class="mapData[value].raw.dictStyle">
            {{ mapData[value][opts.labelField] }}{{opts.suffix}}
          </span>
          <a-badge v-else :key="index"
                   :status="mapData[value].raw.dictStyle"
                   :text="mapData[value][opts.labelField] + opts.suffix" />
        </template>
        <span v-else :key="value">{{ mapData[value][opts.labelField] }}{{opts.suffix}}</span>
      </template>
      <template v-else>
        <span v-bind:key="value">{{value}}</span>
      </template>

      <template v-if="index < values.length - 1">
        {{opts.separator}}
      </template>
    </template>
  </div>
</template>

<script>
  const _opts = {
    valueField: 'value',
    labelField: 'label',
    separator: '|',
    suffix: '', // 后缀
  };
  export default {
    name: "DictTag",
    props: {
      options: {
        type: Object,
      },
      value: [Number, String, Array],
      data: {
        type: Array,
        default: function () {
          return []
        }, // [dictItem, dictItem]
      }
    },
    computed: {
      values() {
        let v
        if (this.value !== null && typeof this.value !== 'undefined') {
          v = Array.isArray(this.value) ? this.value : (this.value + '').split(',');
        } else {
          v = [];
        }
        // console.log("-------", v)
        return v
      },
      opts() {
        return this.$util.mergeObject(_opts, this.options)
      },
      mapData() {
        return this.$util.listToMap(this.data, {idField: this.opts.valueField})
      }
    },
  };
</script>
<style scoped>
</style>
