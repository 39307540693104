import { v4 } from 'uuid'

/**
 * 设置参数到localstorage
 * @param key
 * @param value
 */
export function getItem(key) {
  return localStorage.getItem(key)
}

/**
 * 设置参数到localstorage
 * @param key
 * @param value
 */
export function setItem(key, value) {
  localStorage.setItem(key, value)
}

/**
 * localstorage 移除
 * @param key
 */
export function removeItem(key) {
  localStorage.removeItem(key)
}

/**
 * 设置参数到localstorage
 * @param key
 * @param value
 */
export function getJsonItem(key) {
  return JSON.parse(localStorage.getItem(key))
}

/**
 * 设置参数到localstorage
 * @param key
 * @param value
 */
export function setJsonItem(key, value) {
  localStorage.setItem(key, JSON.stringify(value))
}

// 手机号校验
export function testMobile(value) {
  return /^[1]([3-9])[0-9]{9}$/.test(value)
}

// 密码校验
// 8至16位大小写字母数字下划线特殊字符组合
export function testPassword1(value) {
  return /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/.test(value)
}
// 密码校验
// 非空字符8-16
export function testPassword(value) {
  return /^\S{8,16}$/.test(value)
}

// 身份证校验
export function testIdentity(value) {
  return /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}$)/.test(value)
}

/**
 * 日期格式化
 * @param time
 * @param pattern
 * @returns {string|null}
 */
export function parseTime(time, pattern) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time)
    } else if (typeof time === 'string') {
      time = time
        .replace(new RegExp(/-/gm), '/')
        .replace('T', ' ')
        .replace(new RegExp(/\.[\d]{3}/gm), '')
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}

/**
 * 表单重置
 * @param refName
 */
export function resetForm(refName) {
  if (this.$refs[refName]) {
    this.$refs[refName].resetFields()
  }
}

/**
 * 判断是否空['', null, undefined]，空则给默认值， 0，'0'，false表示有值
 *
 * @param val
 * @param defaultVal
 */
export function ifEmpty(val, defaultVal) {
  return isEmpty(val) ? defaultVal : val
}

/**
 * 判断是否空['', null, undefined]，空则给默认值， 0，'0'，false表示有值
 *
 * @param val
 */
export function isEmpty(val) {
  return val === undefined || val == null || val === ''
}

/**
 * 判断一个值是否是空对象
 * @param val
 * @returns {boolean}
 */
export function isEmptyObject(val) {
  return Object.keys(val).length === 0
}

/**
 * 判断一个数组是否是空数组
 * @param val
 * @returns {boolean}
 */
export function isEmptyArray(val) {
  if (val === undefined || val == null) return true
  if (Array.isArray(val)) {
    return val.length <= 0
  }
  throw new Error('parameter val is not a array')
}

/**
 * 判断一个数组是否不为空
 * @param val
 * @returns {boolean}
 */
export function isNotEmptyArray(val) {
  return !isEmptyArray(val)
}

/**
 * 是否是json对象
 * @param obj
 * @return {boolean}
 */
export function isJson(obj) {
  if (typeof obj == 'object') {
    return true
  }
  return false
}

/**
 * 表单验证失败结果
 * @param obj
 * @return {boolean}
 */
export function isValidFalse(obj) {
  if (isJson(obj) && obj.valid === false) {
    return true
  }
  return false
}

/**
 * 拼接两个path，如果有/，则处理后拼接
 * 返回：/abc/cde 结构
 * 返回：/abc 结构
 * 返回：/ 结构
 * @param path1
 * @param path2
 */
export function concatPath(path1, path2) {
  path1 = path1 || ''
  path2 = path2 || ''
  let str = path1 + '/' + path2
  str = str.replaceAll(/\/+/g, '/')
  if (str.length > 1 && str.charAt(str.length - 1) === '/') {
    str = str.slice(0, -1)
  }
  return str
}

/**
 * 去掉一头一尾的指定字符
 *
 * @param str
 * @param c
 * @returns {*|string}
 */
function sliceStr(str, c) {
  str = str || ''
  // 截取头
  while (str.charAt(0) === c) {
    str = str.slice(1)
  }
  // 截取尾
  while (str.charAt(str.length - 1) === c) {
    str = str.slice(0, -1)
  }
  return str
}

/**
 * 数据对象合并，空则返回{}，一个则复制返回，两个，则从右向左复制，不改变原数据。
 *
 * @param arguments
 * @returns {*}
 */
export function mergeObject() {
  let ret = {}
  let len = arguments.length
  if (len === 0) return ret
  // 定义闭包
  let merge = function (source, target) {
    for (let k in target) {
      try {
        if (target[k]) {
          if (target[k].constructor == Object) {
            source[k] = this.merge(source[k], target[k])
          } else {
            source[k] = target[k]
          }
        } else {
          source[k] = target[k]
        }
      } catch (e) {
        source[k] = target[k]
      }
    }
    return source
  }
  for (let i = 0; i < len; i++) {
    let item = arguments[i]
    ret = merge(ret, item)
  }
  return ret
}

/**
 * 数组转树结构
 * @param {Array} arr 源数据[{id, parentId, xxx},{id, parentId, xxx},]
 * @param {JSON} opts {idField,pidField,rank} 默认用id和parentId两个字段
 * @return ListTree结构
 */
export function listToTree(arr, opts) {
  let data = JSON.stringify(arr)
  data = JSON.parse(data)
  let root = []

  let idField = 'id'
  let pidField = 'parentId'
  if (opts && opts.idField) {
    idField = opts.idField
    pidField = opts.pidField
  }

  // list 转map
  let map = {}
  data.forEach((item) => {
    map[item[idField]] = item
  })
  // 循环，暂时不考虑回环情况（节点的id=parentId）
  data.forEach((node) => {
    let pid = node[pidField] || '0'
    // 查找当前节点的父节点
    if (map[pid]) {
      map[pid].children = map[pid].children || []
      map[pid].children.push(node)
    } else {
      root.push(node)
    }
  })
  return root
}

/**
 * 树结构转数组，把children拿出来
 * @param {Array} arr 源数据[{id, name, path,children:[{}]},{id, name,path, children:[{}]},]
 * @param {JSON} opts {idField,pidField,rank} 默认用id和parentId两个字段
 * @return [{id, name, parentId, path}, {id, name, parentId, path}]
 */
export function treeToList(arr) {
  let data = JSON.stringify(arr)
  data = JSON.parse(data)

  // 定义闭包
  let loop = function (data) {
    let list = []
    data.forEach((node) => {
      if (node.children && node.children.length > 0) {
        let children = node.children
        delete node['children']
        list.push(node)
        list.push(...loop(children))
      } else {
        list.push(node)
      }
    })
    return list
  }
  return loop(data)
}

/**
 * 数组转Map
 * @param {Array} arr 源数据[{id, parentId, xxx},{id, parentId, xxx},]
 * @param {JSON} opts {idField,pidField,rank} 默认用id和parentId两个字段
 */
export function listToMap(arr, opts) {
  let data = JSON.stringify(arr)
  data = JSON.parse(data)

  let idField = 'id'
  if (opts && opts.idField) {
    idField = opts.idField
  }
  let map = {}
  data.forEach((node) => {
    map[node[idField]] = node
  })
  return map
}

/**
 * 遍历json对象，返回label
 *
 * @param {JSON} data eg: {value:'1', label:'这是1', children:[{value:'11', label:'this 11'},{value:'12', label:'this 12'}]},
 * @param {String} splitChar label的分割符 eg: '/'
 * @return json value/label的对象结构，eg：{'1,11': '这是1/this 11', '1,12': '这是1/this 12'}
 */
export function concatMapTreeLabel(data, splitChar) {
  if (isEmpty(data)) {
    return null
  }
  splitChar = splitChar || '/'
  // 无返回值得递归，遍历到叶子节点，处理
  let loop = function (items, map, retMap) {
    retMap = retMap || {}
    items.forEach((item) => {
      let itemMap = { k: map.k, v: map.v }

      itemMap.k = isEmpty(itemMap.k) ? item.value : itemMap.k + ',' + item.value
      itemMap.v = isEmpty(itemMap.v) ? item.label : itemMap.v + splitChar + item.label

      if (item.children && item.children.length > 0) {
        loop(item.children, itemMap, retMap)
      } else {
        retMap[itemMap.k] = itemMap.v
      }
    })
    return retMap
  }
  let retMap = {}
  loop(data, { k: '', v: '' }, retMap)
  return retMap
}

/**
 * 获取tree的某一个item，可能编辑children节点
 *
 * @param {Array} arr 数据源[{id,name, path, children:[{}]}]
 * @param val id = 3
 * @param opts 配置 {idFieldName: id}
 * @return {id, name, parentId, path}
 */
export function getMenuTreeItem(arr, val, opts) {
  let data = arr
  let idField = 'id'
  if (opts && opts['idField']) {
    idField = opts['idField']
  }
  let loop = function (data) {
    let retItem = null
    for (let i = 0; i < data.length; i++) {
      let item = data[i]
      if (item[idField] === val && item.meta.menuType === 'V') {
        retItem = item
      } else if (item.children && item.children.length > 0) {
        retItem = loop(item.children, val, opts)
      }
      if (retItem) {
        return retItem
      }
    }
  }
  let retItem = loop(data)
  return retItem || null
}

/**
 * 查找tree中指定末节点，并处理
 *
 * @param {Array} treeData {value:'1', label:'这是1', children:[{value:'11', label:'this 11'},{value:'12', label:'this 12'}]}
 * @param {Array} ids eg tree的每个节点id层级：ids=[1,102, 10202]
 * @param {function} callback 找到的节点进行回调处理
 * @param {JSON} opts {children:'children|sonNodes', id: 'id | value| key'}
 */
export function computeTreeNode(treeData, ids, callback, opts) {
  if (!treeData || treeData.length <= 0) return
  if (!ids || ids.length <= 0) return

  let id = opts.id || 'id'
  let children = opts.children || 'children'
  let cd = {}
  cd[children] = treeData // 构造出json对象

  let loopTime = 0 // 查找层数
  try {
    ids.forEach((idVal) => {
      let item = cd[children].find((item) => item[id] === idVal)
      if (item) {
        loopTime++ // 找到则加1层
        cd = item
      } else {
        throw new Error(`not fond data：expected: ${ids.length}, but: ${loopTime}`)
      }
    })
  } catch (e) {
    console.log(e)
  }
  callback(cd)
}

/**
 * 从fileList获取文件
 *
 * @param fileList
 * @param index 获取指定的位置index
 */
export function getFileRaw(fileList, index) {
  if (!fileList) return null
  let file = null
  if (fileList && fileList.length > 0) {
    if (index == undefined) {
      file = fileList.map((item) => item.raw)
    } else {
      file = fileList[index].raw
    }
  }
  return file
}

/**
 * 日期加年
 *
 * @param date
 * @param years
 */
export function dateAddYears(date, years) {
  if (!date) return
  date.setYear(date.getFullYear() + years)
  return date
}

/**
 * 日期加月
 *
 * @param date
 * @param months
 */
export function dateAddMonths(date, months) {
  if (!date) return
  date.setMonth(date.getMonth() + months)
  return date
}

/**
 * 日期加天
 *
 * @param date
 * @param days
 */
export function dateAddDays(date, days) {
  if (!date) return
  date.setDate(date.getDate() + days)
  return date
}

/**
 * 获取当前日期范围
 *
 * @param val
 */
export function getRangeYearDays(val) {
  // 1 年
  // 设置日期范围
  if (val) {
    let d = new Date()
    let endDate = parseTime(d, '{y}-{m}-{d}')
    let startDate = parseTime(dateAddDays(dateAddYears(d, -val), 1), '{y}-{m}-{d}')
    return [startDate, endDate]
  }
  return []
}

/**
 * 获取当前日期范围
 *
 * @param val
 */
export function getRangeMonths(val) {
  // 3个月/6个月
  // 设置日期范围
  if (val) {
    val = val - 1
    let d = new Date()
    let endDate = parseTime(d, '{y}-{m}-{d}')
    let startDate = parseTime(dateAddMonths(d, -val), '{y}-{m}' + '-01')
    return [startDate, endDate]
  }
  return []
}

/**
 * 获取当前日期范围
 *
 * @param val
 */
export function getRangeDays(val) {
  // 7天/30天
  // 设置日期范围
  if (val) {
    val = val - 1
    let d = new Date()
    let endDate = parseTime(d, '{y}-{m}-{d}')
    let startDate = parseTime(dateAddDays(d, -val), '{y}-{m}-{d}')
    return [startDate, endDate]
  }
  return []
}

/**
 * 获取连续天
 *
 * @param strStart
 * @param strEnd
 * @returns {[]|*[]}
 */
export function getLinearDayList(strStart, strEnd) {
  if (!strStart || !strEnd) {
    return []
  }
  let s = new Date(strStart)
  let e = new Date(strEnd)
  let ret = []
  let endTime = e.getTime()
  while (s.getTime() <= endTime) {
    ret.push(parseTime(s, '{y}-{m}-{d}'))
    s.setDate(s.getDate() + 1)
  }
  return ret
}

/**
 * 获取连续月
 *
 * @param strStart
 * @param strEnd
 * @returns {[]|*[]}
 */
export function getLinearMonthList(strStart, strEnd) {
  if (!strStart || !strEnd) {
    return []
  }
  let s = new Date(strStart)
  let e = new Date(strEnd)
  let ret = []
  let endTime = e.getTime()
  while (s.getTime() <= endTime) {
    ret.push(parseTime(s, '{y}-{m}'))
    s.setMonth(s.getMonth() + 1)
  }
  return ret
}

/**
 * merge数据，把data中缺失的日期记录补全
 *
 * @param dateList 接口返回最小到最大数组[2022-12, 2023-03]
 * @param list 接口返回的list数据比如却2023-01/02数据 [{date: 2022-12, value:1},{date: 2023-03, value:10}]
 * @param opts 默认补全日期字段 {date:"2023-01-01 | 2022-12"}
 * @returns [{date: 2022-12, value:1},{date: 2023-01, value:0},{date: 2023-02, value:0},{date: 2023-03, value:10}]
 */
export function mergeDataByDate(dateList, list, opts) {
  if (!dateList || dateList.length <= 0) {
    return list
  }
  let valueField = 'date'
  if (opts && opts['valueField']) {
    valueField = opts['valueField']
  }
  return dateList.map((date) => {
    let obj = {}
    obj[valueField] = date
    let dataObj = list.find((item) => item[valueField] == date) || opts.initFields || {}
    return { ...dataObj, ...obj }
  })
}

/**
 * 大写金额金额转换
 *
 * @param val 7287233.23
 * @param type {w | y} 转换目标， w万元，y亿元
 * @param decimal 小数位数
 */
const RMB_NUM = '零壹贰叁肆伍陆柒捌玖' //0-9所对应的汉字
const RMB_UNIT = '万仟佰拾亿仟佰拾万仟佰拾元角分' //数字位所对应的汉字
export function convertWordCurrency(money) {
  //汉字的数字
  let cnNums = new Array('零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖')
  //基本单位
  let cnIntRadice = new Array('', '拾', '佰', '仟')
  //对应整数部分扩展单位
  let cnIntUnits = new Array('', '万', '亿', '兆')
  //对应小数部分单位
  let cnDecUnits = new Array('角', '分', '毫', '厘')
  //整数金额时后面跟的字符
  let cnInteger = '整'
  //整型完以后的单位
  let cnIntLast = '圆'
  //最大处理的数字
  let maxNum = 999999999999999.9999
  //金额整数部分
  let integerNum
  //金额小数部分
  let decimalNum
  //输出的中文金额字符串
  let chineseStr = ''
  //分离金额后用的数组，预定义
  let parts
  if (money == '') {
    return ''
  }
  money = parseFloat(money)
  if (money >= maxNum) {
    //超出最大处理数字
    return ''
  }
  if (money == 0) {
    chineseStr = cnNums[0] + cnIntLast + cnInteger
    return chineseStr
  }
  //转换为字符串
  money = money.toString()
  if (money.indexOf('.') == -1) {
    integerNum = money
    decimalNum = ''
  } else {
    parts = money.split('.')
    integerNum = parts[0]
    decimalNum = parts[1].substr(0, 4)
  }
  // 获取整型部分转换
  if (parseInt(integerNum, 10) > 0) {
    let zeroCount = 0
    let IntLen = integerNum.length
    for (let i = 0; i < IntLen; i++) {
      let n = integerNum.substr(i, 1)
      let p = IntLen - i - 1
      let q = p / 4
      let m = p % 4
      if (n == '0') {
        zeroCount++
      } else {
        if (zeroCount > 0) {
          chineseStr += cnNums[0]
        }
        //归零
        zeroCount = 0
        chineseStr += cnNums[parseInt(n)] + cnIntRadice[m]
      }
      if (m == 0 && zeroCount < 4) {
        chineseStr += cnIntUnits[q]
      }
    }
    chineseStr += cnIntLast
  }
  //小数部分
  if (decimalNum != '') {
    let decLen = decimalNum.length
    for (let i = 0; i < decLen; i++) {
      let n = decimalNum.substr(i, 1)
      if (n != '0') {
        chineseStr += cnNums[Number(n)] + cnDecUnits[i]
      }
    }
  }
  if (chineseStr == '') {
    chineseStr += cnNums[0] + cnIntLast + cnInteger
  } else if (decimalNum == '') {
    chineseStr += cnInteger
  }
  return chineseStr
}

/**
 * 金额单位转换
 *
 * @param val 7287233.23
 * @param type {w | y} 转换目标， w万元，y亿元
 * @param decimal 小数位数
 */
export function convertCurrency(val, type, decimal) {
  let currencyUnit = { w: 10000, y: 100000000 }
  if (isEmpty(val)) return null
  let num = Number(val)
  if (num == 0) return val
  decimal = decimal || 0
  num = num / currencyUnit[type]
  num = num.toFixed(decimal)
  return num
}

/**
 * 金额格式化
 * @param val
 */
export function currencyFormat(val) {
  if (isEmpty(val)) {
    return null
  }
  let ret = ''
  let str = (val + '').split('.')
  let arrInteger = str[0].split('')
  let strDecimal = ''
  if (str.length > 1) {
    strDecimal = '.' + str[1]
  }
  arrInteger.reverse().forEach((c, index) => {
    if (index !== 0 && index % 3 === 0) {
      ret = c + ',' + ret
    } else {
      ret = c + ret
    }
  })
  ret = ret + strDecimal
  return ret
}

/**
 * 比例格式化
 * @param val
 */
export function ratePercent(val) {
  if (isEmpty(val)) {
    return null
  }
  // 0.1 + 0.2
  // 210000 * 10000 * 1000 * 8.2
  // 0.07 * 100
  // 精度可以引用第三方math.js依赖包,npm install mathjs
  let p = '%'
  val = (val * 100).toFixed(2)
  return val + p
}

/**
 * 数字金额格式化
 * @param val
 */
export function decimalFormat(val) {
  if (isEmpty(val)) {
    return 0
  }
  val = val.toFixed(2)
  return Number(val)
}

/**
 * 文件名变更
 *
 * @param fileName
 * @returns 获取去掉扩展名
 */
export function extName(fileName) {
  let f = ['', ''] // 0是文件名，1是文件扩展名
  if (fileName) {
    let index = fileName.lastIndexOf('.')
    f[0] = fileName.substr(0, index)
    f[1] = fileName.substr(index)
  }
  return f
}

/**
 * UUID生成
 */
export function UUID() {
  let newUuid = v4()
  newUuid = newUuid.replace(/[-]/g, '')
  return newUuid
}

/**
 * 文件名变更
 *
 * @param fileName  test.png
 * @param newFileName xuyd，空则用uuid代替
 * @returns 新的文件名 xuyd.png
 */
export function convertFileName(fileName, newFileName) {
  let f = extName(fileName)
  if (newFileName) {
    return newFileName + f[1]
  } else {
    let newUuid = v4()
    newUuid = newUuid.replace(/[-]/g, '')
    return newUuid + f[1]
  }
}

/**
 * 转数组
 * @param value
 * @returns {string[]}
 */
export function toArray(value) {
  let f = Array.isArray(value) ? value : (value + '').split(',')
  return f
}

/**
 * 验证富文本是否为空
 *
 * @param val
 * @returns {boolean}
 */
export function checkEmptyRichText(val) {
  // console.log("------richText------>", val)
  if (isEmpty(val)) {
    return true
  }
  val = val.replace(/<[^<p>]+>/g, '')
  val = val.replace(/<[^</p>]+>/g, '')
  val = val.replace(/<[^<br>]+>/g, '')
  val = val.replace(/&nbsp;/gi, '')
  if (isEmpty(val)) {
    return true
  }
  let reg = new RegExp('[]+$')
  return reg.test(val)
}

/**
 * 富文本内容中img的地址src属性内容替换处理
 *
 * @param url oss的全路径 https://dongfang-yz-shanghai.oss-cn-shanghai.aliyuncs.com/fileXXX
 * @returns {String} /oss/fileXXX
 */
export function convertToOssPrefixPath(content) {
  const endpoint = 'https://dongfang-yz-shanghai.oss-cn-shanghai.aliyuncs.com/'
  if (content) {
    console.log('替换成前缀前====>', content)
    let htmlObject = document.createElement('div')
    htmlObject.innerHTML = content
    let eleList = htmlObject.querySelectorAll(['img', 'IMG', 'image']) || []
    eleList.forEach((item) => {
      if (item.src) {
        item.src = item.src.replace(endpoint, '/oss/')
      }
    })
    console.log('替换成前缀后====>', htmlObject.innerHTML)
    let retContent = htmlObject.innerHTML
    htmlObject.remove()
    return retContent
  }
  return ''
}

/**
 * 富文本内容中img的地址src属性内容替换处理
 *
 * @param content /oss/fileXXX
 * @returns {String} oss的全路径
 */
export function convertToOssFullUrl(content) {
  const endpoint = 'https://dongfang-yz-shanghai.oss-cn-shanghai.aliyuncs.com/'
  if (content) {
    console.log('替换成全路径前====>', content)
    let htmlObject = document.createElement('div')
    htmlObject.innerHTML = content
    let eleList = htmlObject.querySelectorAll(['img', 'IMG', 'image']) || []
    eleList.forEach((item) => {
      if (item.src) {
        // item.src = item.src.replace('/oss/', endpoint)
        let index = item.src.indexOf('/oss/')
        if (index > 0) {
          item.src = endpoint + item.src.substring(index + 5)
        }
      }
    })
    console.log('替换成全路径后====>', htmlObject.innerHTML)
    let retContent = htmlObject.innerHTML
    htmlObject.remove()
    return retContent
  }
  return ''
}

/**
 * 把upload组件用的对象结构转换成db结果对象
 * @param uploadFileResult
 */
export function convertUploadToDb(uploadFileResult) {
  return {
    id: uploadFileResult.id,
    uri: uploadFileResult.uri,
    originalName: uploadFileResult.fileName, // 上传时候传入的fileName
    fileName: uploadFileResult.fileName, // 上传时候传入的fileName
    sort: 0, // 排序字段(默认就是数字下标排序)
    flag: uploadFileResult.flag, // flag :操作标记(1:新增 2:删除 3:无需处理)
    type: 3, // 分类：0其他1文本2视频3图片
  }
}
export function GetQueryString(name) {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  var r = window.location.search.substr(1).match(reg) //获取url中"?"符后的字符串并正则匹配
  var context = ''
  if (r != null) context = decodeURIComponent(r[2])
  reg = null
  r = null
  return context == null || context == '' || context == 'undefined' ? '' : context
}
