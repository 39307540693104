<template>
  <div class="nav-menu" @click="goto">
    <a-tooltip title="客服" placement="bottom">
      <svg-icon icon="icon-kefu" class="nav-icon"/>
    </a-tooltip>
  </div>
</template>

<script>
  export default {
    name: 'Kefu',
    data() {
      return {
        url: ''
      }
    },
    methods: {
      goto() {
        this.$modal.alert('暂无客服')
      }
    }
  }
</script>

<style scoped lang="scss">
  .nav-menu {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      font-size: 20px;
      font-weight: 600
    }
    .nav-icon{
      width: 25px;
      height: 25px;
      overflow: hidden;
    }
  }
</style>
