<template>
  <a-layout id="layout-container" :class="classObj" class="app-wrapper" v-if="topNav">
    <a-layout-header class="layout-nav">
      <Logo style="float: left; width: 258px" />
      <NavMenu />
    </a-layout-header>
    <a-layout>
      <a-layout-sider class="top-index" v-model="sidebar.collapsed" :trigger="null">
        <Sidebar />
      </a-layout-sider>
      <a-layout>
        <NavBreadcrumb v-if="false" />
        <a-layout-content>
          <AppMain />
        </a-layout-content>
      </a-layout>
    </a-layout>
  </a-layout>

  <a-layout id="layout-container" :class="classObj" class="app-wrapper" v-else>
    <a-layout-sider class="top-index" v-model="sidebar.collapsed" :trigger="null">
      <Sidebar />
    </a-layout-sider>
    <a-layout>
      <a-layout-header class="layout-nav">
        <NavMenu />
      </a-layout-header>
      <a-layout>
        <NavBreadcrumb v-if="false" />
        <a-layout-content>
          <AppMain />
        </a-layout-content>
      </a-layout>
    </a-layout>
  </a-layout>
</template>

<script>
import { mapState } from 'vuex'
import { AppMain, Logo, NavBreadcrumb, NavMenu, Sidebar } from './layout.js'
import adapter from './adapter'

export default {
  name: 'Layout',
  components: { AppMain, Sidebar, NavMenu, NavBreadcrumb, Logo },
  data() {
    return {}
  },
  mixins: [adapter],
  mounted: function () {},
  computed: {
    ...mapState({
      device: (state) => state.app.device,
      topNav: (state) => state.app.topNav,
      sidebar: (state) => state.app.sidebar,
      showSettings: (state) => state.app.showSettings,
      affixHeader: (state) => state.app.affixHeader,
    }),
    classObj() {
      return {
        hideSidebar: !this.sidebar.collapsed,
        openSidebar: this.sidebar.collapsed,
        withoutAnimation: this.sidebar.withoutAnimation,
      }
    },
  },
  methods: {},
}
</script>

<style lang="scss">
.app-wrapper {
  position: relative;
  height: 100%;
  width: 100%;

  .layout-nav {
    width: 100%;
    height: 64px;
    padding: 0 0 1px 0;
    margin: 0;
    background: #ffffff;
    border-bottom: 1px solid #efefef;
    box-sizing: border-box;
  }
  .top-index {
    z-index: 9;
  }
}
</style>
