<template>
  <div class="layout-left">
    <!-- logo -->
    <Logo class="sidebar-logo" v-if="!topNav"/>
    <!-- side menu -->
    <SideMenu class="sidebar-menu"/>
    <Hamburger v-if="sidebar.showHamburger"/>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  import Logo from '../Logo'
  import SideMenu from '../SideMenu'
  import Hamburger from '../Hamburger'

  export default {
    components: {Logo, Hamburger, SideMenu},
    computed: {
      ...mapGetters([
        'sidebar',
        'topNav',
      ]),
    },
    methods: {}
  }
</script>
<style lang="scss" scoped>
  .layout-left {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    box-shadow: 2px 2px 4px #00000014;
    -webkit-box-shadow: 2px 2px 4px #00000014;

    display: flex;
    flex-direction: column;

    .sidebar-logo{
      width: 100%;
      height: 64px;
    }
    .sidebar-menu {
      flex: 1;
    }
  }
</style>
