<template>
  <a-page-header v-if="showIcon" style="border: 1px solid rgb(235, 237, 240)" :title="showTitle ? title || $route.meta.title : ''" :subTitle="subTitle" @back="handleBack">
    <template slot="backIcon">
      <div>
        <a-icon type="arrow-left" />
      </div>
    </template>

    <template slot="extra">
      <slot name="content" class="content" v-if="showButton">
        <a-button class="action ml20" @click="handleSave" :loading="saveLoading">{{ buttonList[1].label }}</a-button>
        <a-button type="primary" class="action" @click="handlePublish" :loading="publishLoading">{{ buttonList[0].label }}</a-button>
      </slot>
    </template>
  </a-page-header>

  <a-page-header v-else style="border: 1px solid rgb(235, 237, 240)" :title="showTitle ? title || $route.meta.title : ''" :backIcon="false" :subTitle="subTitle" @back="handleBack">
    <template slot="extra">
      <slot name="content" class="content" v-if="showButton">
        <a-button class="action ml20" @click="handleSave" :loading="saveLoading">{{ buttonList[1].label }}</a-button>
        <a-button type="primary" class="action" @click="handlePublish" :loading="publishLoading">{{ buttonList[0].label }}</a-button>
      </slot>
    </template>
  </a-page-header>
</template>

<script>
export default {
  name: 'PageHeader',
  props: {
    showIcon: {
      type: Boolean,
      default: false,
    },
    showButton: {
      type: Boolean,
      default: false,
    },
    showConfirm: {
      type: Boolean,
      default: true,
    },
    buttonList: {
      type: Array,
      default: () => {
        return [
          { label: '发布', type: 'primary' },
          { label: '保存草稿', type: '' },
        ]
      },
    },
    backName: {
      type: String,
      default: '返回',
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: null,
    },
    subTitle: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: null,
    },
    data: Object,
    saveLoading: {
      type: Boolean,
      default: false,
    },
    publishLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      backPath: '',
    }
  },
  methods: {
    /** 返回 */
    handleBack() {
      if (!this.showConfirm) {
        this.$router.back()
        return
      }
      let title = '是否确认返回？'
      let content = '返回后您填写的内容将不会保存，建议存为草稿'
      let that = this
      that.$modal
        .confirm(content, title, { iconType: 'info-circle', okText: '保存草稿' })
        .then(() => {
          this.$emit('saveClick', {}, () => {
            // that.$router.go(-1);
            that.$router.back()
          })
        })
        .catch(() => {
          // 取消
          that.$router.back()
        })
    },
    /** 发布 */
    handlePublish() {
      this.$emit('publishClick')
    },
    /** 保存 */
    handleSave() {
      this.$emit('saveClick')
    },
  },
}
</script>

<style lang="scss" scoped>
.page-header1 {
  width: 100%;
  height: 74px;
  border: 1px solid rgb(235, 237, 240);
}

.page-header1 {
  width: 100%;
  height: 74px;
  line-height: 74px;
  padding: 10px 24px;
  font-size: 16px;
  /*border-top: 1px solid rgba(233, 233, 233, 1);*/
  border-bottom: 1px solid rgba(233, 233, 233, 1);
  /*border-right: 1px solid rgba(233, 233, 233, 1);*/
  background-color: #ffffff;
  display: flex;
  align-items: center;

  .header-left {
    display: flex;
    cursor: pointer;
    margin-right: 40px;
    position: relative;

    .icon {
      font-size: 18px;
      margin-right: 6px;
      align-self: center;
    }

    .back {
      font-size: 14px;
      font-weight: 500;
    }
  }

  .header-left:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 16px;
    right: -20px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #dcdfe6;
  }

  .title {
    font-size: 20px;
    /*font-family: ".萍方-简 中粗体", ".萍方-简 中黑体", ".萍方-简", sans-serif;*/
    font-weight: 600;
    color: #303133;
  }

  .right {
    flex: 1;
    display: flex;
    justify-content: right;

    .content {
      font-size: 14px;
      color: #666;
      float: right;
      margin-right: 10px;
    }
  }
}

.action {
  width: 96px;
  height: 40px;
  font-size: 16px;
}
</style>
