const filters = {
  AUDIT_STATE: (auditState) => {
    let text = ''
    switch (auditState) {
      case 0:
        text = '待审核'
        break
      case 1:
        text = '审核通过'
        break
      case 2:
        text = '未通过'
        break
      default:
        text = ''
        break
    }
    return text
  },
}
export default filters
