/**
 * 权限指令定义
 */
import store from '@/store'

export default {
  /**
   * 挂在dom时候触发，如果没有权限，则从dom中移除el节点
   * @param el
   * @param binding
   * @param vnode
   */
  inserted(el, binding, vnode) {
    let {value} = binding;
    // 指令参数必须设置
    if (value && value instanceof Array && value.length > 0) {
      // 全权限
      let all_permission = "*:*:*";

      // 获取权限
      let permissions = store.getters && store.getters.permissions;

      // 如果是全权限，或者el的定义匹配用户当前的权限
      let hasPermissions = permissions.some(permission => {
        return all_permission === permission || value.includes(permission)
      });
      if (!hasPermissions) {
        el.parentNode && el.parentNode.removeChild(el);
      }
    } else {
      throw new Error(`权限指令的参数未设置`)
    }
  }
}
