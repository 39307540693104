import store from '@/store'

function isMobile() {
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|iPad|Android|Mobile)/);
  return !!flag;
}

function debounce(callback, timeout) {
  let timer = null;
  return function(value) {
    clearTimeout(timer);
    timer = setTimeout(function() {
      callback(value)
    }, timeout)
  }
}

const resizeFunc = debounce(function () {
  if(document.hidden) {
    return;
  }
  let mobile = isMobile();
  store.dispatch("doToggleDevice", mobile ? 'mobile':'pc');
  if(mobile) {
    store.dispatch("doCloseSideBar", {withoutAnimation: true})
  }
  console.log('---------【device】resize-------', mobile, store.getters.device, navigator.userAgent)
}, 500)

export default {
  watch:{
    $route(route) {
      let mobile = isMobile();
      if(store.getters.device === 'mobile' && store.getters.sidebar.collapsed) {
        store.dispatch("doCloseSideBar", {withoutAnimation: false})
      }
      console.log('---------【device】watch-------', mobile, store.getters.device, navigator.userAgent)
    }
  },
  beforeMount() {
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  mounted() {
    let mobile = isMobile();
    store.dispatch("doToggleDevice", mobile ? 'mobile':'pc');
    if(mobile) {
      store.dispatch("doCloseSideBar", {withoutAnimation: true})
    }
    console.log('---------【device】mounted-------', mobile, store.getters.device, navigator.userAgent)
  },
  methods:{
    handleResize() {
      resizeFunc(null)
    }
  }
}
