import Vue from 'vue'
import Vuex from 'vuex'

import getters from './getters.js'
import app from './modules/app.js'
import globalConfig from './modules/globalConfig'
import user from './modules/user.js'
import dict from './modules/dict.js'
import permission from './modules/permission.js'
import tabsView from './modules/tabsView'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    globalConfig,
    user,
    dict,
    permission,
    tabsView,
  },
  getters
})

Vue.prototype.skin = store.getters.skin
Vue.prototype.tableHeader = {
  'background-color': store.getters.skin.headerBgColor,
  'border-bottom': '1px solid ' + store.getters.skin.headerBorderColor
}
Vue.prototype.tableListSize = 'medium'
Vue.prototype.tableButtonSize = 'mini'
Vue.prototype.formSize = 'small'
Vue.prototype.searchButtonSize = 'mini'
Vue.prototype.funcButtonSize = 'mini'

export default store
