const dictList = {
  // 平台标记
  businessTag: [
    { dictValue: 1, dictText: '个人' },
    { dictValue: 2, dictText: '企业' },
  ],

  // 全部平台类型
  platformType: [
    { dictValue: 1, dictText: '创作者' },
    { dictValue: 2, dictText: '独立店' },
    { dictValue: 3, dictText: '商家' },
    { dictValue: 4, dictText: '运营后台' },
    { dictValue: 5, dictText: '品牌后台' },
  ],

  // C端平台类型
  businessType: [
    { dictValue: 1, dictText: '好看鸭创作者平台' },
    { dictValue: 2, dictText: '艺开店后台' },
    { dictValue: 3, dictText: '好看鸭商家平台' },
    { dictValue: 5, dictText: '品牌管理系统' },
  ],

  // 身份认证状态
  identityState: [
    { dictValue: 0, dictText: '未认证', dictStyle: 'default' },
    { dictValue: 1, dictText: '已认证', dictStyle: 'success' },
    { dictValue: 2, dictText: '未通过认证', dictStyle: 'error' },
  ],

  // 认证类型 1实名认证,2身份认证,3企业真实性验证,4简介认证,5品牌认证,6经营地址认证,7办公地址认证,8线下实体店认证9办公环境认证,10店铺环境认证,11展览认证,12经历认证,13获奖认证
  authType: [
    { dictValue: 1, dictText: '实名认证' },
    { dictValue: 2, dictText: '身份认证' },
    { dictValue: 3, dictText: '企业真实性验证' },
    { dictValue: 4, dictText: '简介认证' },
    { dictValue: 5, dictText: '品牌认证' },
    { dictValue: 6, dictText: '经营地址认证' },
    { dictValue: 7, dictText: '办公地址认证' },
    { dictValue: 8, dictText: '线下实体店认证' },
    { dictValue: 9, dictText: '办公环境认证' },
    { dictValue: 10, dictText: '店铺环境认证' },
    { dictValue: 11, dictText: '展览认证' },
    { dictValue: 12, dictText: '经历认证' },
    { dictValue: 13, dictText: '获奖认证' },
  ],

  // 店铺认证
  shopAuthState: [
    { dictValue: 0, dictText: '未认证', dictStyle: 'default' },
    { dictValue: 1, dictText: '已认证', dictStyle: 'success' },
    { dictValue: 2, dictText: '未通过认证', dictStyle: 'error' },
  ],

  // 经营状态  1-经营 0-打烊
  operatingState: [
    { dictValue: 1, dictText: '经营' },
    { dictValue: 0, dictText: '打烊' },
  ],

  // 入驻状态 0审核中，1已通过，2未通过
  applyState: [
    { dictValue: 0, dictText: '审核中' },
    { dictValue: 1, dictText: '已通过' },
    { dictValue: 2, dictText: '未通过' },
  ],

  // 内容分类 1动态2视频3作品4文章
  contentType: [
    { dictValue: 1, dictText: '动态' },
    { dictValue: 2, dictText: '视频' },
    { dictValue: 3, dictText: '作品' },
    { dictValue: 4, dictText: '文章' },
  ],
  contentEditCompType: [
    { dictValue: 1, dictText: 'TrendEdit' },
    { dictValue: 2, dictText: 'VideoEdit' },
    { dictValue: 3, dictText: 'ProductionEdit' },
    { dictValue: 4, dictText: 'ArticleEdit' },
  ],
  contentListCompType: [
    { dictValue: 1, dictText: 'TrendList' },
    { dictValue: 2, dictText: 'VideoList' },
    { dictValue: 3, dictText: 'ProductionList' },
    { dictValue: 4, dictText: 'ArticleList' },
  ],

  // 内容状态 0:草稿 100：未发布 200：已发布
  contentStatus: [
    { dictValue: '0', dictText: '草稿箱' },
    { dictValue: 100, dictText: '未发布', dictStyle: 'default' },
    { dictValue: 200, dictText: '已发布', dictStyle: 'success' },
  ],

  // 内容状态 0:未发布 1：已发布
  publishStatus: [
    { dictValue: 0, dictText: '未发布', dictStyle: 'default' },
    { dictValue: 1, dictText: '已发布', dictStyle: 'success' },
  ],

  EmployeeStatus: [
    { dictValue: 1, dictText: '已启用', dictStyle: 'primary' },
    { dictValue: 2, dictText: '禁用', dictStyle: 'error' },
  ],

  GENDER: [
    { dictValue: 1, dictText: '男' },
    { dictValue: 0, dictText: '女' },
  ],
  STORETYPE: [
    { dictValue: 1, dictText: '直营', count: 0 },
    { dictValue: 2, dictText: '分销商', count: 0 },
    { dictValue: 3, dictText: '经销商', count: 0 },
    { dictValue: 4, dictText: '其他', count: 0 },
  ],
  APPLYSTATE: [
    //0待审核，1已通过，2已拒绝
    { dictValue: 0, dictText: '待通过', color: 'red' },
    { dictValue: 1, dictText: '已通过' },
    { dictValue: 2, dictText: '已拒绝' },
  ],
}

export default dictList
