/**
 * focus指令定义
 */
export default {
  /**
   * 挂在dom时候触发，如果没有权限，则从dom中移除el节点
   * @param el
   * @param binding
   * @param vnode
   */
  inserted(el, binding, vnode) {
    let {value} = binding;
    if(el.nodeName == "INPUT") {
      el.focus()
    } else {
      el.querySelector('input').focus()
    }
  }
}
