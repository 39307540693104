import {message, Modal} from 'ant-design-vue'
import store from "@/store";

let loadingInstance;

export default {
  // 消息提示
  msg(content) {
    message.info(content)
  },
  // 错误消息
  msgError(content) {
    message.error(content)
  },
  // 成功消息
  msgSuccess(content) {
    message.success(content)
  },
  // 警告消息
  msgWarning(content) {
    message.warning(content)
  },
  // 弹出提示
  alert(content) {
    return new Promise((resolve, reject) => {
      Modal.error({
        title: '系统提示',
        content: content,
        okText: '确定',
        onOk() {
          resolve()
        },
      })
    })
  },
  // 错误提示
  alertError(content) {
    return new Promise((resolve, reject) => {
      Modal.error({
        title: '系统提示',
        content: content,
        okText: '确定',
        onOk() {
          resolve()
        },
      })
    })
  },
  // 成功提示
  alertSuccess(content) {
    return new Promise((resolve, reject) => {
      Modal.success({
        title: '系统提示',
        content: content,
        okText: '确定',
        onOk() {
          resolve()
        },
      })
    })
  },
  // 警告提示
  alertWarning(content) {
    return new Promise((resolve, reject) => {
      Modal.warning({
        title: '系统提示',
        content: content,
        okText: '确定',
        onOk() {
          resolve()
        },
      })
    })
  },

  // // 通知提示
  // notify(content) {
  //   Notification.info(content)
  // },
  // // 错误通知
  // notifyError(content) {
  //   Notification.error(content);
  // },
  // // 成功通知
  // notifySuccess(content) {
  //   Notification.success(content)
  // },
  // // 警告通知
  // notifyWarning(content) {
  //   Notification.warning(content)
  // },
  // 确认窗体

  confirm(content, title, options) {
    return new Promise((resolve, reject) => {
      Modal.confirm({
        centered: true,
        iconType: options?.iconType || 'question-circle',
        title: title || '系统提示',
        content: content,
        okText: options?.okText || '确定',
        cancelText: '取消',
        onOk() {
          resolve()
        },
        onCancel() {
          reject()
        }
      })
    })
  },

  // 打开遮罩层
  loading(content) {
    // loadingInstance = Loading.service({
    //   lock: true,
    //   text: content,
    //   spinner: "el-icon-loading",
    //   background: "rgba(0, 0, 0, 0.7)",
    // })
    loadingInstance = message.loading(content, 0)
  },
  // 关闭遮罩层
  closeLoading() {
    loadingInstance.close();
  }
}
