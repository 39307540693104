import {DictItem, DictMeta} from './DictEntity'
import DictOptions from './DictOptions'
import {concatMapTreeLabel, mergeObject} from '@/utils/util'
import Vue from 'vue'
import store from '@/store/index'

/**
 * dict数据组件类，Dict类被实例化，加入到data里面，所以页面可以直接调用。
 * data(){
 *     return {dict:new Dict()}
 * }
 * 在init里面设置dict对象上的label的dictType值，即dict.label.sys_yes_no
 * 在init里面设置dict对象上的type的dictType值，即dict.type.sys_yes_no
 *
 * @param Vue
 * @param options
 */
export default class Dict {
  constructor(owner) {
    this.owner = owner
    // 列表页面用于value和label的转换（字典比数组更快）
    this.label = {}
    // 检索域的下拉框，表单的下拉等
    this.type = {}
  }

  /**
   * 加载数据字典对象
   *
   * @param {String/JSON} dictType sys_yes_no / {dictType: 'AM_MANAGER_DUET_TYPE', dataType: String}
   * @return 单个结果的Promise
   */
  initDict(dictType) {
    let options = DictOptions;
    // 数组参数，转成单个参数对象
    return new Promise((resolve) => {
      // init 返回promiseDictTypeList = [];数组promise
      this.init([dictType], options).then(data => {
        resolve(data[0]);
      });
    });
  }

  /**
   * 加载数据字典对象
   *
   * @param dictTypes [sys_yes_no, {dictType: 'AM_MANAGER_DUET_TYPE', dataType: String}]
   * @param options vue $options
   * @return 数组结果的Promise
   */
  init(dictTypes, options) {
    if (!dictTypes || !(dictTypes instanceof Array) || dictTypes.length <= 0) {
      throw new Error('dict types can not empty, e.g: dictTypes:["sys_yes_no"]');
    }
    // 定义各个dictType的promise结果容器
    let promiseDictTypeList = [];
    // 这里的this是页面上的dict对象
    // 实例化类，创建各自的meta数据
    this._dictMetas = dictTypes.map(t => parseMeta(t, options));

    // 根据store中存储有无，来决定是否要调用接口，需要调用接口的放到List中，一次调用，返回map。
    // 根据_dictMetas拆分出dictTree和dict调用不同接口
    let dictReq = {dictMetas: [], dictTreeMetas: []};

    let that = this;
    // [{dictType, dataType, isTree, treeLevel}]
    this._dictMetas.forEach(dictMeta => {
      // 初始化
      let dictType = dictMeta.isTree ? (dictMeta.dictType + dictMeta.treeLevel) : dictMeta.dictType;
      // 设置dict对象上的label值，即dict.label.sys_yes_no
      Vue.set(this.label, dictType, {})

      // 设置dict对象上的type值，即dict.type.sys_yes_no
      Vue.set(this.type, dictType, [])

      // 判断store是否存在
      let dictItems = store.state.dict.dict[dictType];
      if (dictItems) {
        let retPromise = Promise.resolve(dictItems).then(dictItems => {
          let dictType = dictMeta.isTree ? (dictMeta.dictType + dictMeta.treeLevel) : dictMeta.dictType;
          dictItems = dictItems || [];
          // 设置组件dict.type的字典值
          that.type[dictType] = dictItems;
          // eg: dict.label.sys_yes_no.Y = '是'
          // eg: dict.label.sys_yes_no.N = '否'
          // eg: dict.label.sys_yes_no[0] = '否'
          // eg: dict.label.sys_yes_no[1] = '是'
          that.label[dictType] = concatMapTreeLabel(dictItems, '/');
          let mapData = {};
          mapData[dictType] = dictItems;
          return mapData;
        });
        promiseDictTypeList.push(retPromise);
      } else {
        if (dictMeta.isTree) {
          dictReq.dictTreeMetas.push(dictMeta);
        } else {
          dictReq.dictMetas.push(dictMeta);
        }
      }
    });

    // 发送请求，服务端加载数据字典对象
    if (dictReq.dictMetas && dictReq.dictMetas.length > 0) {
      let retPromise = options.requestDictBatch(dictReq.dictMetas).then(mapData => {
        let promises = [];
        dictReq.dictMetas.forEach(dictMeta => {
          let dictItems = mapData[dictMeta.dictType] || [];
          that.type[dictMeta.dictType] = dictItems;
          that.label[dictMeta.dictType] = concatMapTreeLabel(dictItems, '/');
        });
        return mapData;
      }).catch(err => {
        console.log(err);
      });
      promiseDictTypeList.push(retPromise)
    }

    // 发送请求，服务端加载数据字典对象
    if (dictReq.dictTreeMetas && dictReq.dictTreeMetas.length > 0) {
      let retPromise = options.requestDictTreeBatch(dictReq.dictTreeMetas).then(mapData => {
        // let promises = [];
        dictReq.dictTreeMetas.forEach(dictMeta => {
          let dictType = dictMeta.isTree ? (dictMeta.dictType + dictMeta.treeLevel) : dictMeta.dictType;
          let dictItems = mapData[dictType] || [];
          that.type[dictType] = dictItems;
          that.label[dictType] = concatMapTreeLabel(dictItems, '/');
        });
        return mapData;
      }).catch(err => {
        console.log(err);
      });
      promiseDictTypeList.push(retPromise);
    }
    return Promise.all(promiseDictTypeList)
  }
}

/**
 * 配置元数据的定义，即从元数据复制出各自dictType的元数据
 *
 * dictTypes实际的类型：[sys_yes_no, {dictType: sys_yes_no}]
 *
 * @param dictType 为'sys_yes_no'或者{dictType:sys_yes_no}
 * @param options dict的元数据 {dictType, dataType, isTree, treeLevel, request, labelField, valueField, fieldNameConverter}}}
 */
function parseMeta(dictType, options) {
  let opts = {};
  if (typeof dictType === 'string') {
    opts = options.metas[dictType] || {};
    opts.dictType = dictType;
    opts.dataType = Number;
  } else if (typeof dictType === 'object') {
    if (!dictType.dataType) {
      throw new Error("dictType=" + dictType.dictType + "，dataType must be a empty.");
    }
    opts = dictType
  }
  // 通过options定义生成
  // {request, labelField, valueField }, {type} => {request, labelField, valueField, dictType}
  opts = mergeObject(options.metas['*'], opts);
  // 设置数据转换
  if (opts.isTree) {
    opts.fieldNameConverter = convertDicTreeValueLabel;
  } else {
    opts.fieldNameConverter = convertValueLabel;
  }
  return new DictMeta(opts);
}

/**
 * 普通字典转value和label字段名
 * @param dictMeta 元数据
 * @param data 数据
 * @return {Array} 转换后数据结构（value,label）
 */
function convertValueLabel(dictMeta, data) {
  if (!data || data.length <= 0) {
    return null;
  }
  let retArray = [];
  data.forEach(item => {
    let val = item[dictMeta.valueField];
    if(dictMeta.exclude && dictMeta.exclude.includes(val)) {
      return
    }
    if (dictMeta.dataType) {
      if (dictMeta.dataType === 'String') {
        val = String(val);
      } else if (dictMeta.dataType === 'Number') {
        val = Number(val);
      } else {
        val = dictMeta.dataType(val);
      }
    } else {
      val = Number(val);
    }
    retArray.push(new DictItem(val, item[dictMeta.labelField], null, {dictStyle: item.dictStyle, cssClass: item.cssClass}));
  });
  return retArray;
}

/**
 * 字典树转value和label字段名
 * @param dictMeta 元数据
 * @param data 数据
 * @return 转换后数据结构（value,label,children）
 */
function convertDicTreeValueLabel(dictMeta, data) {
  if (!data || data.length <= 0) {
    return null;
  }
  return data.map(item => {
    let val = item[dictMeta.valueField];
    return new DictItem(val, item[dictMeta.labelField], convertDicTreeValueLabel(dictMeta, item.children), {
      dictStyle: item.dictStyle,
      cssClass: item.cssClass
    });
  });
}
