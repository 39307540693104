import {getJsonItem, setJsonItem} from '@/utils/util'

function setVisitedViews(val) {
  setJsonItem("visitedViews", val);
}

function getVisitedViews() {
  return getJsonItem("visitedViews") || [];
}

function setCachedViews(val) {
  setJsonItem("cachedViews", val);
}

function getCachedViews() {
  return getJsonItem("cachedViews") || [];
}

const tabsView = {
  state: {
    /**
     * 访问过的页面
     * [
     * {
     * fullPath: "/system/user?id=1", name: "SysUser",params: {},path: "/system/user",query: {},
     * meta: {title: "用户管理", menuType: "V", icon: null, cache: true, link: false, linkType: null},
     * }
     * ]
     */
    visitedViews: getVisitedViews(),
    /**
     * 缓存的页面，keep-alive 的include的组件名称
     * [{name}]
     */
    cachedViews: getCachedViews(),
  },
  mutations: {
    /**
     * 添加到访问过列表
     * @param state
     * @param view this.$route
     */
    addVisitedView: (state, view) => {
      // 存在相同的path，说明已经打开过
      // 如果fullPath一样，则直接return
      // 如果fullPath不一样，则参数不一样，则覆盖
      let vv = state.visitedViews.find(v => v.path === view.path);
      if (vv) {
        if (vv.fullPath === view.fullPath) {
          return;
        } else {
          // 覆盖
          vv.fullPath = view.fullPath;
          vv.query = {...view.query};
          vv.params = {...view.params};
          vv.meta = {...view.meta};
        }
      } else {
        state.visitedViews.push(view);
      }
      setVisitedViews(state.visitedViews);
    },

    /**
     * 添加到缓存过列表
     */
    addCachedView: (state, view) => {
      // 存在name，直接return
      if (state.cachedViews.includes(view.name)) return;
      // 如果需要缓存则缓存
      if (view.meta && view.meta.cache) {
        state.cachedViews.push(view.name)
      }
      setCachedViews(state.cachedViews);
    },
    /**
     * 从访问列表指定删除一个
     */
    delVisitedView: (state, view) => {
      for (let [i, v] of state.visitedViews.entries()) {
        if (v.path === view.path) {
          state.visitedViews.splice(i, 1)
          break;
        }
      }
      setVisitedViews(state.visitedViews);
    },
    /**
     * 从缓存列表指定删除一个
     */
    delCachedView: (state, view) => {
      let index = state.cachedViews.indexOf(view.name)
      // splice() 方法会改变原始数组。
      index > -1 && state.cachedViews.splice(index, 1)

      setCachedViews(state.cachedViews);
    },

    /**
     * 从访问列表删除所有
     * 固定的不删除（meta.affix）
     */
    delAllVisitedViews: (state) => {
      state.visitedViews = state.visitedViews.filter(v => {
        if (v.meta.affix) {
          return true;
        }
        // 从cache删除(不能把affix的删除掉）
        let cacheIndex = state.cachedViews.indexOf(v.name)
        cacheIndex > -1 && state.cachedViews.splice(cacheIndex, 1);
        return false;
      })
      setVisitedViews(state.visitedViews);
      setCachedViews(state.cachedViews);
    },

    /**
     * 从访问列表删除指定一个以外
     * 固定的不删除（meta.affix）
     */
    delOtherVisitedViews: (state, view) => {
      state.visitedViews = state.visitedViews.filter(v => {
        if (v.meta.affix || v.path === view.path) {
          return true;
        }
        // 从cache删除(不能把affix的删除掉）
        let cacheIndex = state.cachedViews.indexOf(v.name)
        cacheIndex > -1 && state.cachedViews.splice(cacheIndex, 1);
        return false;
      })
      setVisitedViews(state.visitedViews);
      setCachedViews(state.cachedViews);
    },

    /**
     * 删除当前左边
     */
    delLeftVisitedViews: (state, view) => {
      if (state.visitedViews.length <= 1) return;
      let index = state.visitedViews.findIndex(v => v.path === view.path);
      if (index === -1) return;

      state.visitedViews = state.visitedViews.filter((v, i) => {
        if (i >= index || (v.meta && v.meta.affix)) {
          return true;
        }
        // 从cache删除(不能把affix的删除掉）
        let cacheIndex = state.cachedViews.indexOf(v.name)
        cacheIndex > -1 && state.cachedViews.splice(cacheIndex, 1);

        return false;
      })
      setVisitedViews(state.visitedViews);
      setCachedViews(state.cachedViews);
    },

    /**
     * 删除当前右边
     */
    delRightVisitedViews: (state, view) => {
      if (state.visitedViews.length <= 1) return;
      let index = state.visitedViews.findIndex(v => v.path === view.path);
      if (index === -1) return;
      state.visitedViews = state.visitedViews.filter((v, i) => {
        if (i <= index || (v.meta && v.meta.affix)) {
          return true;
        }
        // 从cache删除(不能把affix的删除掉）
        let cacheIndex = state.cachedViews.indexOf(v.name)
        cacheIndex > -1 && state.cachedViews.splice(cacheIndex, 1);

        return false;
      })
      setVisitedViews(state.visitedViews);
      setCachedViews(state.cachedViews);
    },

    /**
     * 更新指定的访问过的列表项
     */
    updateVisitedView: (state, view) => {
      for (let v of state.visitedViews) {
        if (v.path === view.path) {
          v = Object.assign(v, view)
          break
        }
      }
      setVisitedViews(state.visitedViews);
    },
  },
  actions: {
    /**
     * 新增view
     * @param commit
     * @param state
     * @param view
     */
    doAddView({commit, state}, view) {
      return new Promise(resolve => {
        commit('addVisitedView', view);
        commit('addCachedView', view);
        resolve({
          visitedViews: [...state.visitedViews],
          cachedViews: [...state.cachedViews]
        });
      });
    },

    /**
     * 删除指定
     * @param commit
     * @param state
     * @param view
     * @returns {Promise<unknown>}
     */
    doDelView({commit, state}, view) {
      return new Promise(resolve => {
        commit('delVisitedView', view);
        commit('delCachedView', view);
        resolve({
          visitedViews: [...state.visitedViews],
          cachedViews: [...state.cachedViews]
        })
      })
    },
    /**
     * 删除指定缓存，用于重新加载
     * @param commit
     * @param state
     * @param view
     * @returns {Promise<unknown>}
     */
    doDelCachedView({commit, state}, view) {
      return new Promise(resolve => {
        commit('delCachedView', view);
        resolve([...state.cachedViews])
      });
    },

    /**
     * 删除指定以外
     * @param commit
     * @param state
     * @param view
     * @returns {Promise<unknown>}
     */
    doDelOtherView({commit, state}, view) {
      return new Promise(resolve => {
        commit('delOtherVisitedViews', view);
        resolve({
          visitedViews: [...state.visitedViews],
          cachedViews: [...state.cachedViews]
        });
      })
    },

    /**
     * 删除指定左边
     * @param commit
     * @param state
     * @param view
     * @returns {Promise<unknown>}
     */
    doDelLeftView({commit, state}, view) {
      return new Promise(resolve => {
        commit('delLeftVisitedViews', view);
        resolve({
          visitedViews: [...state.visitedViews],
          cachedViews: [...state.cachedViews]
        });
      })
    },

    /**
     * 删除指定右边
     * @param commit
     * @param state
     * @param view
     * @returns {Promise<unknown>}
     */
    doDelRightView({commit, state}, view) {
      return new Promise(resolve => {
        commit('delRightVisitedViews', view);
        resolve({
          visitedViews: [...state.visitedViews],
          cachedViews: [...state.cachedViews]
        });
      })
    },

    /**
     * 删除所有
     * @param commit
     * @param state
     * @param view
     * @returns {Promise<unknown>}
     */
    doDelAllViews({commit, state}) {
      return new Promise(resolve => {
        commit('delAllVisitedViews');
        resolve({
          visitedViews: [...state.visitedViews],
          cachedViews: [...state.cachedViews]
        });
      })
    },
    /**
     * 更新view
     * @param commit
     * @param view
     */
    doUpdateView({commit}, view) {
      commit('updateVisitedView', view);
    },

    /**
     * 关闭页面（打开的详情页面等）
     * 页面调用：this.$store.dispatch('doClosePage', this.$route).then(openView => this.$router.back());
     * @param commit
     * @param state
     * @param view
     * @returns {Promise<unknown>}
     */
    doClosePage({commit, state}, view) {
      if (state.visitedViews.length <= 1) {
        return Promise.reject();
      }
      return new Promise((resolve, reject) => {
        commit('delVisitedView', view);
        commit('delCachedView', view);
        let visitedViews = [...state.visitedViews];
        if (visitedViews.length > 0) {
          let latestView = visitedViews.slice(-1)[0];
          resolve(latestView);
        } else {
          reject();
        }
      });
    },
  }
}

export default tabsView

