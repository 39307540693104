const getters = {
  token: (state) => state.user.token,
  userSession: (state) => state.user.userSession,

  avatar: (state) => state.user.userSession.avatar,
  name: (state) => state.user.userSession.name,
  businessUserId: (state) => state.user.userSession.businessUserId,
  businessList: (state) => state.user.userSession.businessList,
  businessType: (state) => state.user.userSession.businessType,
  businessTag: (state) => state.user.userSession.businessTag,
  permissions: (state) => state.user.userSession.permissions,

  sidebar: (state) => state.app.sidebar,
  // size: state => state.app.size,
  device: (state) => state.app.device,
  skin: (state) => state.app.skin,
  topNav: (state) => state.app.topNav,

  visitedViews: (state) => state.tabsView.visitedViews,
  cachedViews: (state) => state.tabsView.cachedViews,
  routes: (state) => state.permission.routes,
  menuRoutes: (state) => state.permission.menuRoutes,
  menuCopyRoutes: (state) => state.permission.menuCopyRoutes,
  lastActiveMenuPath: (state) => state.permission.lastActiveMenuPath,
  messageMap: (state) => state.app.messageMap,
}
export default getters
