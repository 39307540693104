import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import dictList from '@/api/dictConfig'
import 'normalize.css/normalize.css'
import '@/assets/css/index.scss' // global css
import './assets/antd/ant-design-ui'
import './assets/icons/index' // svg-icon
import './assets/js/index' // common.js
import filters from '@/utils/filter.js'
// import jquery from 'jquery'
// Vue.prototype.$ = jquery

import moment from 'moment'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')

for (const key in filters) {
  if (Object.hasOwnProperty.call(filters, key)) {
    console.log(key)
    Vue.filter(key, filters[key])
  }
}
// import '../mock/mock.js';

import * as util from '@/utils/util.js'
Vue.prototype.$util = util

import * as VideoUtil from '@/utils/VideoUtil'
Vue.prototype.VideoUtil = VideoUtil
Vue.prototype.$dictMap = dictList

// TODO 无法分开两个服务，则在app启动加载
Vue.prototype.$ffmpeg = VideoUtil.initFFmpeg()

// oss 地址注册成全局
import { ossUrl, convertToPrefixPath } from '@/components/AliOss/OssClient.js'
Vue.prototype.ossUrl = ossUrl
Vue.prototype.convertToPrefixPath = convertToPrefixPath
Vue.filter.ossUrl = ossUrl

// 自定义指令
import directive from './directive'
Vue.use(directive)

// 字典标签组件
import DictTag from '@/components/DictTag'
Vue.component('DictTag', DictTag)
// 字典数据组件
import DictData from '@/components/DictData'
DictData.install()

// 对话框组件等
import Modal from '@/components/Modal'
Vue.prototype.$modal = Modal

// 下载方法
import { download } from '@/utils/request'
Vue.prototype.download = download

// 分页组件
import Pagination from '@/components/Pagination'
Vue.component('Pagination', Pagination)

import BackTop from '@/components/BackTop'
Vue.component('BackTop', BackTop)

// PageHeader组件
import PageHeader from '@/components/PageHeader'
Vue.component('PageHeader', PageHeader)

// TextAreaEx
import TextArea from '@/components/TextArea'
Vue.component('TextAreaEx', TextArea)

Vue.config.productionTip = false

// 默认的配置，用于表单布局
Vue.prototype.$wrapperCol = {
  xl: 6,
  md: 8,
  sm: 24,
}
// 三个的配置，用于表单布局
Vue.prototype.$wrapperThreeCol = {
  xl: 8,
  md: 8,
  sm: 24,
}
// 表单独占一半的配置，用于表单布局
Vue.prototype.$wrapperHalfCol = {
  xxl: 6,
  xl: 12,
  md: 16,
  sm: 24,
}
// import dayJs from 'dayjs';
// import 'dayjs/locale/zh-cn';
// dayJs.locale('zh-cn');

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
