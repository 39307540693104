import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import { getToken } from '@/utils/auth.js'
import Layout from '@/layout'
import NProgress from 'nprogress'

NProgress.configure({
  showSpinner: false,
})

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch((err) => err)
}

export const constantRoutes = [
  {
    path: '/',
    component: Layout,
    redirect: '/index',
  },
  {
    path: '/index',
    component: Layout,
    redirect: '/content/trendPublish/edit',
  },
  {
    path: '',
    component: Layout,
    meta: {
      menuType: 'C',
    },
    children: [
      {
        path: '/index',
        component: () => import('@/views/home/index'),
        meta: {
          menuType: 'V',
          title: '欢迎页',
          cache: false,
        },
      },
      {
        name: 'UserProfile',
        path: '/user/profile',
        component: () => import('@/views/sys/user/profile'),
        meta: {
          menuType: 'V',
          title: '账号信息',
          cache: false,
        },
      },
    ],
  },
  {
    path: '/auth-redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/auth-redirect/:path(.*)',
        component: () => import('@/views/redirect'),
      },
    ],
  },
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect'),
      },
    ],
  },

  {
    path: '/404',
    component: () => import('@/views/error/404'),
    hidden: true,
  },
  {
    path: '/401',
    component: () => import('@/views/error/401'),
    hidden: true,
  },
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true,
  },
]

/**
 * 动态路由
 * @type {*[]}
 */
export const dynamicRoutes = []

console.log('加载路由配置：', constantRoutes)

let router = new VueRouter({
  mode: 'history',
  routes: constantRoutes,
})
var allRouterPath = []
/**
 * 白名单路由
 * @type {string[]}
 */
const whiteList = ['/login', '/auth-redirect', '/bind', '/register']

NProgress.configure({
  showSpinner: false,
})
/**
 * 页面跳转拦截（导航守卫）
 */
router.beforeEach((to, from, next) => {
  NProgress.start()

  if (from.query.contentType && from.name !== 'MaterialStore') {
    to.query.contentType = from.query.contentType
  }
  // if (process.env.VUE_APP_ENV !== "production") {
  //   try {
  //     console.log('页面跳转', 'from=', from, "to=", to);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  // 含有三级及多级的parentView的页面向上提一级，才能keepalive
  handleKeepAlive(to)

  // 白名单判断
  if (whiteList.indexOf(to.path) !== -1) {
    next()
  } else {
    let token = getToken()
    if (token) {
      // 如果用户session信息为空则获取
      if (store.getters.businessList.length === 0) {
        // console.log(location.href + '-----> 已经登录，获取用户session')
        // 获取用户信息
        store
          .dispatch('doGetUserSession')
          .then(() => {
            // 获取菜单路由
            store.dispatch('doGenMenuRouteList').then((menuRoutes) => {
              // 动态添加可访问路由表
              let tree = createTree(menuRoutes)
              // console.log(tree)
              allRouterPath = []
              menuRoutes.forEach((item) => {
                allRouterPath.push(item.path)
                router.addRoute(item)
              })
              if (allRouterPath.includes(to.path)) {
                next({ ...to, replace: true })
              } else {
                // console.log('没有路由', tree[0].children[0])
                let obj = tree[0].children[0]
                next({ ...obj, replace: true })
              }
            })
          })
          .catch((err) => {
            // 抛异常，则返回退出
            store.dispatch('doLogout').then(() => {
              next({ path: '/' })
            })
          })
      } else {
        next()
      }
    } else {
      // console.log(location.href + '的token为空，未登录')
      // ue-router3.0.7之后即3.1.0之后，this.$router.push()返回的是Promise对象，由于导航守卫处理return，没有一个返回值，所以抛异常
      // 在创建router实例对象之前，手动覆盖原型链的push来吞掉报错catch
      // next({ name: "login" }).catch(err => err);
      next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
      // router.push("/login").catch(err => err);
    }
  }
})

/**
 * 递归处理多余的 layout : <router-view>，
 * 让需要访问的组件保持在第一层 index : <router-view> 之下
 * @param to
 */
function handleKeepAlive(to) {
  if (to.matched && to.matched.length > 2) {
    for (let i = 0; i < to.matched.length; i++) {
      const element = to.matched[i]
      // 因为import()异步懒加载,第一次获取不到element.components.default.name , 所以不能再beforeEach做,不然第一次访问的界面不缓存第二次才会缓存
      // afterEach就不一样了,这时候可以获取到element.components.default.name了
      if (element.components.default && element.components.default.name === 'ParentView') {
        to.matched.splice(i, 1)
        handleKeepAlive(to)
      }
    }
  }
}
function createTree(data, parentId = 0) {
  let tree = []
  for (let item of data) {
    if (item.parentId === parentId) {
      const children = createTree(data, item.id)
      if (children.length) {
        item.children = children
      }
      tree.push(item)
    }
  }
  return tree
}
function findLastChild(data) {
  for (let i = 0; i < data.length; i++) {
    const item = data[i]
    if (!item.children) {
      return item
    } else {
      findLastChild(item.children)
    }
  }
}
router.afterEach((to, from) => {
  NProgress.done()
})

export default router
