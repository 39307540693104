/**
 * dict项的实体类定义
 * @property {String} value 值
 * @property {String} label 显示内容
 * @property {Array} children 如果是字典树则是子节点，否则null
 * @property {JSON} raw 原始数据
 */
export class DictItem {
  constructor(value, label, children, raw) {
    this.value = value
    this.label = label
    if (children) {
      this.children = children;
    }
    this.raw = raw;
  }
}

/**
 * 字典元数据
 *
 * @property {String} dictType 字典类型区分
 * @property {String} dataType 数据类型，字符串（需要转换）还是Number（默认）数字类型
 * @property {Array} exclude 普通类型字典时候，排除指定value项
 * @property {Boolean} isTree 是否是下拉树结构
 * @property {Number} treeLevel 下拉树层级（空|0:所有级数; 1,2:1和2级; !0: 除了0级以外;!1:除了1级以外; !1,2,3,!4: 除了1和4，只包含2和3级）
 * @property {Function} request 请求
 * @property {String} label 标签字段
 * @property {String} value 值字段
 */
export class DictMeta {
  constructor(options) {
    this.dictType = options.dictType;
    this.dataType = options.dataType;
    this.exclude = options.exclude;
    this.isTree = options.isTree || false;
    this.treeLevel = options.treeLevel || '';
    this.request = options.request;
    this.fieldNameConverter = options.fieldNameConverter;
    this.labelField = options.labelField;
    this.valueField = options.valueField;
    this.lazy = options.lazy === true;
  }
}
