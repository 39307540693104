import { removeBusinessUser, removeToken, setBusinessUser, setToken } from '@/utils/auth.js'
import { getUserPlatform, login, loginBySmsCode, loginByWechatQr, logout, oss } from '@/api/auth.api.js'
import { setJsonItem } from '@/utils/util'
import * as Encryptor from '@/utils/encryptor'
import { log } from 'mathjs'
function clearTabViews() {
  setJsonItem('visitedViews', null)
  setJsonItem('cachedViews', null)
}

const user = {
  /**
   *
   * "userId": "202200000000316",
   * "businessUserId": "",
   * "companyName": "",
   * "shopName": "",
   * "name": "NJ54553",
   * "sex": 0,
   * "wechatName": "微信昵称",
   * "wechatOpenId": "t8KV9RGt713XlP2Ic16tOw==",
   * "phone": "13917955923",
   * "token": "Bearer eyJhbGciOiJIUzUxMiJ9.eyJqdGkiOiI4NmM5ZDczZjVjYjQ0N2NmYjFhZGJkZjE4MGE1Y2RlMCIsInVzZXIiOiIxMzkxNzk1NTkyMyIsInN1YiI6IjEzOTE3OTU1OTIzIn0.t94NP0WZ3GXMFJ7z3jGm0OonoiCgV9QTt5gS39cEf1bkssSgu-tDb9yvRfXIJC3yOrV-vZj4T8QpARsK5DfMpw",
   * "refreshToken": "",
   * "state": 0,
   * "ifBlack": 0,
   * "sessionKey": "",
   * "ifFirst": 0,
   * "headImage": "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTJfx47BbWRsD68N0JvtL7GB5qGdypyELkXutUVIct8wI735M4LMzBAnibPxECwfQqRfTvuXtXqPu0A/132",
   * "ip": "127.0.0.1",
   * "region": "中国",
   * "businessTag": 0,
   * "businessType": 0,
   * "businessList": [
   *  {
   * 		"businessTag": 1,
   * 		"businessType": 1,
   * 		"businessUserId": "202200000000317",
   * 		"companyName": "vv"
   * 	}
   * ]
   *
   */
  state: {
    token: null,
    OSSConfig: {
      region: 'oss-cn-shanghai',
      bucket: 'dongfang-yz-shanghai',
    },
    userSession: {
      name: null,
      userId: null,
      businessUserId: null,
      businessType: null,
      shopName: null,
      account: null, // data.phone
      avatar: null, // data.headImage

      businessList: [], // data.businessList 【用户角色（身份）多平台账户】
      roles: [],
      permissions: [],
    },
  },
  mutations: {
    setToken: (state, token) => {
      state.token = token
    },
    setUserSession: (state, userSession) => {
      state.userSession = userSession
    },
    SET_OSS: (state, { accessKeyId, accessKeySecret }) => {
      state.OSSConfig.accessKeyId = accessKeyId
      state.OSSConfig.accessKeySecret = accessKeySecret
      console.log(state.OSSConfig)
    },
  },
  actions: {
    /**
     * 设置用户登录session
     * @param commit
     * @param session 用户登录信息
     */
    doSetSession({ commit }, session) {
      return new Promise((resolve, reject) => {
        setToken(session.token)
        commit('setToken', session.token)
        commit('setUserSession', { businessList: session.businessList })
        resolve(session)
      })
    },

    /**
     * 用户登录
     * @param commit
     * @param loginForm 用户登录信息
     */
    doLogin({ commit }, loginForm) {
      return new Promise((resolve, reject) => {
        // {userId, userName, avatar, depart, position, roles:[], permission:[]}
        let promise = null
        if (loginForm.loginType === 'pwdLogin') {
          promise = login({
            area: loginForm.area,
            phone: loginForm.mobilePhone,
            password: loginForm.password,
          })
        } else if (loginForm.loginType === 'smsCodeLogin') {
          promise = loginBySmsCode({
            area: loginForm.area,
            phone: loginForm.mobilePhone,
            // smsCodeKey: loginForm.smsCodeKey,
            verificationCode: loginForm.smsCode,
          })
        } else if (loginForm.loginType === 'wechatQrLogin') {
          promise = loginByWechatQr({ code: loginForm.code })
        } else {
          reject('未选择登录方式')
          return
        }
        promise
          .then((data) => {
            setToken(data.token)
            commit('setToken', data.token)
            commit('setUserSession', { businessList: data.businessList })
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    getOss({ commit, state }) {
      return new Promise((resolve, reject) => {
        // 调用接口
        oss()
          .then((res) => {
            const accessKeyId = Encryptor.decryptOSS(res.keyId)
            const accessKeySecret = Encryptor.decryptOSS(res.keySecret)
            commit('SET_OSS', {
              accessKeyId: accessKeyId,
              accessKeySecret: accessKeySecret,
            })
            resolve(state.OSSConfig)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    /**
     * 用户logout
     * @param commit
     */
    doLogout({ commit, dispatch, state }) {
      return new Promise((resolve, reject) => {
        // 调用接口
        logout()
          .then(() => {
            // dispatch('doClearSession');
            commit('setToken', '')
            // commit('setUserSession', null)
            removeToken()
            removeBusinessUser()
            dispatch('doDelAllViews')
            resolve()
          })
          .catch((err) => {
            console.error(err)
            // dispatch('doClearSession');
            commit('setToken', '')
            // commit('setUserSession', null)
            removeToken()
            removeBusinessUser()
            dispatch('doDelAllViews')
            reject(err)
          })
      })
    },
    doClearSession({ commit, dispatch }) {
      commit('setToken', '')
      // commit('setUserSession', null)
      removeToken()
      dispatch('doDelAllViews')
    },

    /**
     * 用户个人信息获取
     * @param commit
     */
    doGetUserSession({ commit }) {
      return new Promise((resolve, reject) => {
        // 调用接口
        // {userId, userName, avatar, depart, position, roles:[], permissions:[]}
        getUserPlatform()
          .then((data) => {
            if (!data || !data.businessList || data.businessList.length <= 0) {
              reject('当前用户还未入驻平台')
              return
            }
            let userSession = {}
            userSession.name = data.name
            userSession.userId = data.userId
            userSession.phone = data.phone
            userSession.avatar = data.headImage
            userSession.businessList = data.businessList || []

            userSession.businessType = data.businessType
            userSession.businessUserId = data.businessUserId
            userSession.businessTag = data.businessTag
            userSession.shopName = data.shopName
            userSession.userPlatType = data.userPlatType // userPlatType = 9 标识该businessUserId下员工
            commit('setUserSession', userSession)
            resolve()
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
  },
}

export default user
