import { getStore, setStore } from '@/utils/auth.js'
import defaultSettings from '@/settings.js'
import variables from '@/assets/css/variables.scss'
import messageModel from '@/api/brandManage/message'
const storageSetting = JSON.parse(localStorage.getItem('layout-setting')) || ''

const app = {
  state: {
    sidebar: {
      showHamburger: defaultSettings.showHamburger,
      // 菜单是否打开（移动端和pc端）
      collapsed: getStore('sidebarStatus') ? !!+getStore('sidebarStatus') : false,
      // 菜单折叠打开
      openedSnapshot: getStore('sidebarStatus') ? !!+getStore('sidebarStatus') : true, // 保存opened的快照用于其他非/index，/home路由时候恢复
      withoutAnimation: false,
    },
    device: 'pc',
    theme: storageSetting.theme || variables.primaryBgColor,
    skin: {
      sideBarWidth: variables.sideBarWidth, // 侧边栏宽度
      topNavHeight: variables.topNavHeight, // 顶部一级菜单高度

      primaryBgColor: variables.darkred, // 主颜色

      topNavBgColor: variables.topNavBgColor, //顶部一级菜单背景色
      topNavColor: variables.topNavColor, // 顶部一级菜单默认字体色
      topNavActiveColor: variables.topNavActiveColor, //顶部一级菜单选中后字体色

      sidebarBackgroundColor: variables.sidebarBackgroundColor, // 侧边栏背景色
      sidebarHoverBgColor: variables.sidebarHoverBgColor, // 侧边栏移动上背景色
      sidebarActiveBgColor: variables.sidebarActiveBgColor, // 侧边栏选中后背景色
      sidebarTextColor: variables.sidebarTextColor, // 侧边栏字体色
      sidebarActiveColor: variables.sidebarActiveColor, // 侧边栏选中后字体色

      headerBgColor: variables.headerBgColor, // 标题栏背景色
      headerBorderColor: variables.headerBorderColor, // 标题栏边框线条色
    },
    topNavHeight: variables.topNavHeight,
    topNav: defaultSettings.topNav,
    showSettings: defaultSettings.showSettings,
    affixHeader: defaultSettings.affixHeader,
    sidebarLogo: defaultSettings.sidebarLogo,
    messageMap: {},
  },
  mutations: {
    /**
     * 侧边栏切换
     * @param state
     */
    setToggleSideBar: (state) => {
      state.sidebar.collapsed = !state.sidebar.collapsed
      state.sidebar.openedSnapshot = state.sidebar.collapsed
      state.sidebar.withoutAnimation = false
      if (state.sidebar.collapsed) {
        setStore('sidebarStatus', 1)
      } else {
        setStore('sidebarStatus', 0)
      }
    },
    /**
     * 关闭侧边栏
     * @param state
     * @param withoutAnimation
     */
    setCloseSideBar: (state, withoutAnimation) => {
      // 保存快照
      state.sidebar.openedSnapshot = state.sidebar.collapsed
      // 关闭
      state.sidebar.collapsed = false
      state.sidebar.withoutAnimation = withoutAnimation
    },
    /**
     * 恢复侧边栏
     * @param state
     * @param withoutAnimation
     */
    setRecoverSideBar: (state, withoutAnimation) => {
      // 用快照状态恢复
      state.sidebar.collapsed = state.sidebar.openedSnapshot
      state.sidebar.withoutAnimation = withoutAnimation
    },
    /**
     * 设备切换
     * @param state
     * @param device
     */
    setToggleDevice: (state, device) => {
      state.device = device
    },
    setMessage: (state, value) => {
      state.messageMap = value
    },
  },
  actions: {
    doToggleSideBar({ commit }) {
      commit('setToggleSideBar')
    },
    /**
     * 关闭侧边栏（/index，/home）
     * @param commit
     */
    doCloseSideBar({ commit }, { withoutAnimation }) {
      commit('setCloseSideBar', withoutAnimation)
    },
    /**
     * 恢复侧边栏（非/index，/home）
     * @param commit
     */
    doRecoverSideBar({ commit }, { withoutAnimation }) {
      commit('setRecoverSideBar', withoutAnimation)
    },
    doToggleDevice({ commit }, device) {
      commit('setToggleDevice', device)
    },
    getMessgeCount({ commit }) {
      return new Promise((resolve, reject) => {
        messageModel.myMsgCount().then((res) => {
          commit('setMessage', res)
          resolve(res)
        })
      })
    },
  },
}

export default app
