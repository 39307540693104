<template>
  <i class="anticon" :class="svgClass">
    <svg class="svg" aria-hidden="true" v-on="$listeners">
      <use :xlink:href="iconName"/>
    </svg>
  </i>
</template>
<script>
  export default {
    name: 'SvgIcon',
    props: {
      icon: {
        type: String,
        required: true
      },
      className: {
        type: String,
        default: ''
      }
    },
    computed: {
      iconName() {
        return `#icon-${this.icon}`
      },
      svgClass() {
        if (this.className) {
          return 'svg-icon ' + this.className
        } else {
          return 'svg-icon'
        }
      },
    }
  }
</script>

<style scoped>
  .svg-icon {
    width: 20px;
    height: 20px;
    overflow: hidden;
  }
  .svg{
    width: 100%;
    height: 100%;
  }
</style>
