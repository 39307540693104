import dictApi from '@/api/sys/dict.api'
import dictTreeApi from '@/api/sys/dictTree.api'

const dict = {
  state: {
    dict: {},
  },
  mutations: {
    putDict: (state, dict) => {
      state.dict[dict.dictType] = dict.data;
    },
  },
  actions: {
    /**
     * 字典数据批量查询
     * @param commit
     * @param dictMetas []
     * @returns {Promise<unknown>}
     */
    doSelectDictBatch({commit}, dictMetas) {
      return new Promise((resolve, reject) => {
        let dictTypes = dictMetas.map(item => item.dictType);
        dictApi.selectDict(dictTypes).then(data => {
          let retData = {};
          dictMetas.forEach(dictMeta => {
            let convertedData = dictMeta.fieldNameConverter(dictMeta, data[dictMeta.dictType]);
            commit('putDict', {dictType: dictMeta.dictType, data: convertedData});
            retData[dictMeta.dictType] = convertedData;
          });
          resolve(retData);
        }).catch(err => {
          reject(err)
        })
      })
    },
    /**
     * 字典树数据批量查询
     *
     * @param commit
     * @param dictMetas
     * @returns {Promise<unknown>}
     */
    doSelectDictTreeBatch({commit}, dictMetas) {
      return new Promise((resolve, reject) => {
        let dictTreeTypes = [];
        dictMetas.forEach(dictMeta => {
          dictTreeTypes.push({dictType: dictMeta.dictType, treeLevel: dictMeta.treeLevel});
        });
        dictTreeApi.selectDictTree(dictTreeTypes).then(data => {
          let retData = {};
          dictMetas.forEach(dictMeta => {
            // 防止系统中有全部字典，也有只要1级的树字典，所以key带上层级参数
            // 比如，类型是：COUNTRY_AREA，A页面需要省市两级：1,2，则最后key是COUNTRY_AREA1,2
            let dictType = dictMeta.dictType;
            if (dictMeta.treeLevel) {
              dictType += dictMeta.treeLevel;
            }
            let convertedData = dictMeta.fieldNameConverter(dictMeta, data[dictType]);
            commit('putDict', {dictType: dictType, data: convertedData});
            retData[dictType] = convertedData;
          });
          resolve(retData);
        }).catch(err => {
          reject(err)
        })
      })
    },
  }
}

export default dict


