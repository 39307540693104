// import Cookies from 'js-cookie'
import {getItem, setItem, removeItem} from './util'
const options = {
  sameSite: 'none',
  secure: true,
}
const Cookies = {
  get: getItem,
  set: setItem,
  remove: removeItem,
}
const TokenKey = 'token'
const BusinessUserKey = 'businessUser'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
  // return Cookies.set(TokenKey, token, options)
}

export function getStore(key) {
  return Cookies.get(key)
}

export function setStore(key, value) {
  return Cookies.set(key, value)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getBusinessUser() {
  let bu = Cookies.get(BusinessUserKey)
  return JSON.parse(bu);
}

export function setBusinessUser(businessUser) {
  return Cookies.set(BusinessUserKey, JSON.stringify(businessUser))
}

export function removeBusinessUser() {
  return Cookies.remove(BusinessUserKey)
}
