const global = {
  state: {
    loading: false,
  },
  mutations: {
    setLoading: (state, loading) => {
      state.loading = loading;
    },
  },
  actions: {
    doLoading({commit}, loading) {
      commit("setLoading", loading);
    },
  },

  getters: {
    isLoading: (state) => {
      return state.loading
    }
  }
}

export default global

