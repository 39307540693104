import Vue from 'vue'
import DictPlugin from './DictPlugin'
import DictOptions from './DictOptions'

function install() {
  Vue.use(DictPlugin, DictOptions)
}

export default {
  install,
}
