<template>
  <router-view/>
</template>

<script>
  export default {
    name: 'ParentView',
    data() {
      return {}
    },
  }
</script>
