<template>
  <a-popover overlayClassName="msg-nav-menu" v-model="visible" trigger="click" placement="bottomRight">
    <div class="title" slot="title">
      <h4>消息 （{{ messageMap.notReadCount }} 条未读）</h4>
      <!-- <a-button type="link">全部标记已读</a-button> -->
    </div>
    <div slot="content" class="content">
      <div class="content-inner" v-if="messageMap.notReadCount>0">
        <div class="item" v-for="item in messageList" :key="item.id" @click="goto">
          <div class="top">
            <h4 class="content-title text">{{ item.title }}</h4>
            <div class="time">{{ moment(item.readTime).format('YYYY-MM-DD hh:mm:ss') }}</div>
          </div>
          <div class="bottom text">{{ item.contentText }}</div>
        </div>
      </div>
      <div class="empty" v-else>
        <img src="@/assets/img/u278.png" />
        <div>已读完所有消息</div>
      </div>
      <div class="footer">
        <a-button type="link" @click="goto">查看全部消息</a-button>
      </div>
    </div>

    <a-badge class="badge" :dot=" messageMap.notReadCount > 0" :offset="[-17,25]">

      <div class="nav-menu" @click="goto">
        <a-tooltip title="站内信" placement="bottom">
          <svg-icon icon="icon-msg" class="nav-icon" />
        </a-tooltip>
      </div>
    </a-badge>
  </a-popover>
</template>

<script>
const emptySrc = require('@/assets/img/u278.png')
import messageModel from '@/api/brandManage/message'
import moment from 'moment'
import { mapGetters } from 'vuex'
export default {
  name: 'Msg',
  data() {
    return {
      emptySrc,
      url: '',
      visible: false,
      messageList: [],
    }
  },

  computed: {
    ...mapGetters(['messageMap']),
  },
  mounted() {
    this.initData()
    this.initCount()
    this.timer = setInterval(this.initCount, 5000)
  },
  beforeDestroy() {
    if (this.timer) {
      window.clearInterval(this.timer)
    }
  },
  methods: {
    moment,
    goto() {
      // this.$modal.alert('暂无消息')
      this.$router.push({
        name: 'Message',
      })
      this.visible = false
    },
    readMessage() {
      this.initData()
      this.initCount()
    },
    initData() {
      messageModel
        .msgList({
          page: 1,
          pageSize: 10,
          status: 1,
          userId: this.$store.state.user.userSession.userId,
        })
        .then((res) => {
          this.messageList = res.list
          this.total = res.total
          if (this.messageList.length) {
            this.visible = true
          }
        })
    },
    initCount() {
      this.$store.dispatch('getMessgeCount').then((res) => {
        this.notReadCount = res.notReadCount
      })
    },
  },
}
</script>
<style>
.msg-nav-menu .ant-popover-inner-content {
  padding: 0;
}
</style>
<style scoped lang="scss">
.badge {
  height: 100%;
  position: relative;
  padding: 0 10px;
  .nav-menu {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      font-size: 20px;
      font-weight: 600;
    }
    .nav-icon {
      width: 25px;
      height: 25px;
      overflow: hidden;
    }
  }
}
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
h4 {
  font-weight: bold;
}
.text {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
}
.content {
  width: 400px;

  .content-inner {
    max-height: 600px;
    overflow: auto;
    .item {
      padding: 20px 20px;
      border-bottom: 1px solid #e8e8e8;
      cursor: pointer;
      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .content-title {
          flex: 1;
        }
        .time {
          text-align: right;
          width: 160px;
        }
      }
      .bottom {
        width: 100%;
      }
    }
    .item:last-child {
      border-bottom: none;
    }
    .item:hover {
      background: rgba(163, 214, 221, 0.2);
    }
  }
  .footer {
    border-top: 1px solid #e8e8e8;
    text-align: center;
    padding: 10px;
  }
}

.msg-nav-menu {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    font-size: 20px;
    font-weight: 600;
  }

  .empty {
    color: #ccc;
    text-align: center;
    margin: 30px 0;
    img {
      width: 200px;
      height: auto;
    }
  }
}
</style>
