<template>
  <div class="menu-container">
    <!--  :text-color="skin.sidebarTextColor"-->
    <!--  :active-text-color="skin.sidebarActiveColor"-->
    <!--  :background-color="skin.sidebarBackgroundColor"-->

    <a-menu mode="inline" :openKeys="openKeys" :selectedKeys="selectedKeys" :inline-collapsed="isCollapse" @openChange='handleOpenChange' @select="handleSelect">
      <template v-for="item in sidebarMenuRoutes">
        <SidebarItem :item="item" :key="item.path" />
      </template>
    </a-menu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SidebarItem from './sidebarItem'

export default {
  components: { SidebarItem },
  data() {
    return {
      openKeys: null,
      selectedKeys: null,
    }
  },
  computed: {
    ...mapGetters(['sidebar']),

    /**
     * 菜单数据容器（用于显示菜单）
     */
    sidebarMenuRoutes() {
      return this.$store.getters.menuRoutes
    },
    /**
     * 菜单数据副本容器（用于关联查找数据，包含全量路由配置）
     */
    sidebarMenuCopyRoutes() {
      return this.$store.getters.menuCopyRoutes
    },

    skin() {
      return this.$store.state.app.skin
    },
    isCollapse() {
      return !this.$store.getters.sidebar.collapsed
    },
  },

  watch: {
    $route() {
      this.updateMenuByRoute()
    },
  },
  created() {
    this.updateMenuByRoute()
  },
  /**
   * 菜单图标需要在后台配置：名称如下

门店icon：menu-store 素材icon：menu-material 设置icon：menu-set
   */
  methods: {
    /**
     * 菜单文件夹选择打开
     */
    updateMenuByRoute() {
      // 打开目录
      let openKeys = this.findMenuParentPath()
      this.openKeys = this.openKeys || []
      openKeys.forEach((item) => {
        if (!this.openKeys.includes(item)) {
          this.openKeys.push(item)
        }
      })
      console.log(this.$route.path)
      // 选择菜单（不是页面）
      let p = this.findMenuPath()
      if (p) {
        this.selectedKeys = [p, this.$route.path]
      } else {
        this.selectedKeys = []
      }
      console.log('监听到路由变化', this.openKeys, this.selectedKeys, this.$route.path)
    },
    /**
     * 菜单文件夹选择打开
     */
    findMenuParentPath() {
      // 根据当前路由查找父级节点path
      let path = this.$route.path
      let list = []
      let item = this.sidebarMenuCopyRoutes[path]
      while (item && item.parentPath) {
        list.push(item.parentPath)
        item = this.sidebarMenuCopyRoutes[item.parentPath]
      }
      return list
    },

    /**
     * 活动菜单，和topNav一样
     * 通过当前路由（页面）找到父path（菜单）
     */
    findMenuPath() {
      let path = this.$route.path
      let item = this.sidebarMenuCopyRoutes[path]
      // 如果是iframe，则直接是当前菜单显示，如果是页面则是上一级（菜单）显示
      if (item) {
        if (item.meta.link) {
          return item.path
        } else {
          return item.parentPath || ''
        }
      }
      return null
    },

    /**
     * submenu 回调
     */
    handleOpenChange(openKeys) {
      this.openKeys = openKeys
    },

    /**
     * 菜单选择事件
     *
     * @param item VueComponent(a-menu-item)
     * @param key /system/workdate
     * @param selectedKeys  ["/system/workdate"]
     */
    handleSelect({ item, key, selectedKeys }) {
      this.selectedKeys = selectedKeys
      let menuItem = item.value
      let path = key

      // 如果是外链且是blank打开
      // 如果是外链且是iframe打开则和普通路由一样处理
      if (menuItem.meta.link && menuItem.meta.linkType === 'blank') {
        // blank / iframe
        window.open(path, '_blank') // _blank（默认）,_self,_parent,_top, framename
        return
      }

      // 顶部导航时候，保存当前模块的最后一次访问地址{topNavKey: '/system', lastActiveMenuKey: '/system/user'};
      // let query = null;
      // let topNavPath = null;
      // if (path.indexOf("/") > -1) {
      //   let tmpPath = path.substring(1, path.length);
      //   topNavPath = "/" + tmpPath.substring(0, tmpPath.indexOf("/"));
      //   let payload = {topNavKey: topNavPath, lastActiveMenuKey: path};
      //   if (query) {
      //     payload.lastActiveMenuQuery = query;
      //   }
      //   this.$store.dispatch('doLastActiveMenuPath', payload);
      // }

      let to = { path: path, query: menuItem.query }
      this.$router.push(to)
    },
  },
}
</script>
<style lang="scss" scoped>
/*.menu-container .ant-menu-sub.ant-menu-inline > .ant-menu-item,*/
/*.menu-container .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title*/
/*.menu-container .ant-menu-vertical > .ant-menu-item,*/
/*.menu-container .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,*/
/*.menu-container .ant-menu-vertical-left > .ant-menu-item,*/
/*.menu-container .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,*/
/*.menu-container .ant-menu-vertical-right > .ant-menu-item,*/
/*.menu-container .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,*/
/*.menu-container .ant-menu-inline > .ant-menu-item,*/
/*.menu-container .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title*/
/*{*/
/*  height: 46px;*/
/*  line-height: 46px;*/
/*}*/

.menu-container .ant-menu-inline,
.menu-container .ant-menu-vertical,
.menu-container .ant-menu-vertical-left {
  border-right: 0;
}

.menu-container {
  width: 199px;
  overflow-x: hidden;
  overflow-y: auto;
}

.item {
  display: flex;
  vertical-align: center;

  .icon {
    margin-top: 10px;
  }

  .label {
    margin-left: 10px;
  }
}

.hideSidebar {
  .label {
    margin-left: 0;
  }
}
</style>
