<template>
  <div id="refAppMain" class="app-main" ref="refAppMain">
    <transition name="fade-transform" mode="out-in">
      <keep-alive :include="cachedViews">
        <a-spin wrapper-class-name="global-spin" :spinning="loading">
          <router-view :key="key"/>
        </a-spin>
      </keep-alive>
    </transition>
  </div>
</template>

<script>
  export default {
    name: 'AppMain',
    computed: {
      cachedViews() {
        // ['Home','User']组件名称
        return this.$store.state.tabsView.cachedViews
      },
      key() {
        return this.$route.fullPath
      },
      loading() {
        return this.$store.getters.isLoading
      }
    }
  }
</script>

<style lang="scss">
  .app-main {
    width: 100%;
    /* 140 navbar + tabs-view + 底部栏 = 64 + 40 + 40 */
    height: calc(100vh - 64px - 1px);
    /* 100navbar + tabs-view = 60 + 34 */
    padding: 0;
    margin: 0;
    position: relative;
    overflow: auto;
    background-color: #FFFFFF;
  }

  .global-spin {
    height: 100%;

    .ant-spin-container {
      height: 100%;
    }
  }

  .layout-vertical {
    font-size: 14px;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;

    .layout-search {
      width: 100%;
      background: #fff;
      border-radius: 6px;
      padding: 24px 24px 0 24px;
      /*overflow: auto;*/
    }

    .layout-function {
      /*height: 60px;*/
      width: 100%;
      /*margin-top: 10px;*/
      background: #fff;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      padding: 5px 24px;
      /*overflow: hidden;*/
      display: flex;
      justify-items: center;
    }

    .layout-result {
      flex: 1;
      width: 100%;
      background: #fff;
      /*border-bottom-left-radius: 6px;*/
      /*border-bottom-right-radius: 6px;*/
      padding: 24px 24px;
    }
  }


</style>
