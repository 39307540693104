import Dict from './Dict'

export default function (Vue, options) {
  // mixin处理
  Vue.mixin({
    data() {
      // 把dictType注入到data里面
      if (this.$options === undefined || !this.$options.dictTypes || this.$options.dictTypes.length <= 0) {
        return {}
      }
      const dict = new Dict(this);
      return {
        dict,
      }
    },

    created() {
      if (!this.dict || !(this.dict instanceof Dict)) {
        return
      }
      // 手动创建dict对象的场景，this.dict存在的，但是dictTypes可能不存在，此时也不初始化
      if (!this.$options.dictTypes) {
        return;
      }
      this.dict.init(this.$options.dictTypes, options).then(() => {
        // 数据获取后，如果有监听，则执行监听方法
        options.onReady && options.onReady(this.dict);

        // 如果页面的method中定义监听时间，则触发监听时间
        this.$nextTick(() => {
          this.$emit('dictReady', this.dict);
          if (this.$options.methods && this.$options.methods.onDictReady instanceof Function) {
            this.$options.methods.onDictReady.call(this, this.dict);
          }
        });
      });
    }
  });
}
