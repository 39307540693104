<!-- //
倒计时组件
@auth xuyd
@date 2022-09-10
//-->
<template>
  <a-button type="primary" class="button" :class="[sendOk ? 'active' : '', clazz]" @click="handleSendMsg">{{sendMsgText}}</a-button>
</template>

<script>
  export default {
    name: 'CountDown',
    components: {},
    data() {
      return {
        sendMsgText: '发送验证码',
        sendOk: false,
        freezeTime: 60,
      };
    },
    props: {
      clazz: String,
      mobilePhone: String,
    },
    watch: {
      sendOk: {
        handler: function (val) {
          this.countDownTime(val)
        },
        immediate: true
      }
    },
    methods: {
      /**
       * 倒计时
       */
      countDownTime(val) {
        let that = this;
        let handle = null;
        let setIntervalImmediately = (func, interval) => {
          func();
          return setInterval(func, interval)
        }
        let tarFunc = function () {
          if (that.freezeTime <= 0) {
            that.sendOk = false
            clearInterval(handle)
          } else {
            that.freezeTime--;
            that.sendMsgText = `重新发送（${that.freezeTime}s）`
          }
        };
        if (val) {
          // handle && clearInterval(handle)
          handle = setIntervalImmediately(tarFunc, 1000);
        } else {
          that.sendMsgText = '发送验证码';
        }
      },

      /**
       * 倒计时
       */
      handleSendMsg() {
        if (this.sendOk) return
        this.freezeTime = 60
        this.$emit('sendClick', {}, () => {
          this.sendOk = true
          console.log('发送成功')
        })
      },
    },
  };
</script>

<style scoped lang="scss">
  .button {
    font-weight: 500;
    font-size: 12px;
    color: #FFFFFF;
    border-radius: 2px;
    border-color: #1E1E1E;
    background-color: #1E1E1E;
  }

  .active {
    color: #1E1E1E !important;
    background-color: #FFFFFF !important;
  }
</style>
