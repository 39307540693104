<template>
  <div class="nav-menu" @click="toggleSideBar">
    <a-tooltip :title="sidebar.collapsed ? '展开侧边栏' : '关闭侧边栏'" placement="topLeft" arrow-point-at-center>
      <a-icon :type="sidebar.collapsed ? 'menu-unfold': 'menu-fold'" style="font-weight: 400"></a-icon>
      <span v-if="!sidebar.collapsed">关闭侧边栏</span>
    </a-tooltip>
  </div>
</template>
<script>
  import {mapState} from "vuex";

  export default {
    name: 'Hamburger',
    computed: {
      ...mapState({
        sidebar: state => state.app.sidebar,
      }),
    },
    methods: {
      toggleSideBar() {
        this.$store.dispatch('doToggleSideBar')
      },
    }
  }
</script>

<style scoped lang="scss">
  @import '@/assets/css/variables.scss';

  .nav-menu {
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #e7eaec;
    background-color: $primary;
    cursor: pointer;

    &:hover {
      background-color: #e6f7ff;
    }

    i, span {
      font-size: 14px;
      color: #000000;
      padding: 0 5px;
    }
  }
</style>
