// 根据权限菜单路由获取
import Layout from '@/layout/index'
import Iframe from '@/layout/Iframe'
import store from '../../store/index'
import { getMenuRouteList, getAllMenuRouteList } from '@/api/auth.api.js'
import ParentView from '@/components/ParentView'
import { isExternal, concatPath, listToTree, treeToList, listToMap } from '@/utils/util.js'
const defaultTestRoutes = [
  // {
  //   component: 'Layout',
  //   hidden: false,
  //   id: 4002,
  //   meta: { title: '门店', menuType: 'M', icon: 'menu-content', cache: false, link: false, linkType: '' },
  //   name: 'BrandManage',
  //   parentId: 0,
  //   path: '/brandManage',
  //   query: '',
  // },
  // {
  //   component: 'brandManage/store/manage/index',
  //   hidden: false,
  //   id: 400201101,
  //   meta: { title: '门店管理', menuType: 'V', icon: '', cache: false, link: false, linkType: '' },
  //   name: 'Manage',
  //   parentId: 4002,
  //   path: 'manage',
  //   query: '',
  // },
  // {
  //   component: 'brandManage/store/manage/detail',
  //   hidden: true,
  //   id: 400201102,
  //   meta: { title: '门店详情', menuType: 'Q', icon: '', cache: false, link: false, linkType: '' },
  //   name: 'Detail',
  //   parentId: 4002,
  //   path: 'detail',
  //   query: '',
  // },
  // {
  //   component: 'brandManage/store/data/index',
  //   hidden: true,
  //   id: 400201103,
  //   meta: { title: '门店数据', menuType: 'V', icon: '', cache: false, link: false, linkType: '' },
  //   name: 'StoreData',
  //   parentId: 4002,
  //   path: 'storeData',
  //   query: '',
  // },
  // {
  //   component: 'Layout',
  //   hidden: true,
  //   id: 7002,
  //   meta: { title: '消息提醒', menuType: 'M', icon: '', cache: false, link: false, linkType: '' },
  //   name: 'SiteNotification',
  //   parentId: 0,
  //   path: '/siteNotification',
  //   query: '',
  // },
  // {
  //   component: 'brandManage/message/index',
  //   hidden: true,
  //   id: 7002001,
  //   meta: { title: '消息提醒', menuType: 'V', icon: '', cache: false, link: false, linkType: '' },
  //   name: 'Notification',
  //   parentId: 7002,
  //   path: 'notification',
  //   query: '',
  // },
  // {
  //   component: 'Layout',
  //   hidden: false,
  //   id: 4003,
  //   meta: { title: '素材', menuType: 'M', icon: 'menu-content', cache: false, link: false, linkType: '' },
  //   name: 'Material',
  //   parentId: 0,
  //   path: '/material',
  //   query: '',
  // },
  // {
  //   component: 'brandManage/material/store/index',
  //   hidden: true,
  //   id: 400301,
  //   meta: { title: '素材库', menuType: 'V', icon: '', cache: false, link: false, linkType: '' },
  //   name: 'MaterialStore',
  //   parentId: 4003,
  //   path: 'materialStore',
  //   query: '',
  // },
  // {
  //   component: 'brandManage/material/publishImageText/index',
  //   hidden: true,
  //   id: 400301,
  //   meta: { title: '发图文', menuType: 'V', icon: '', cache: false, link: false, linkType: '' },
  //   name: 'MaterialPublishImageText',
  //   parentId: 4003,
  //   path: 'materialPublishImageText',
  //   query: '',
  // },
  // {
  //   component: 'brandManage/material/publishVideo/index',
  //   hidden: true,
  //   id: 400301,
  //   meta: { title: '发视频', menuType: 'V', icon: '', cache: false, link: false, linkType: '' },
  //   name: 'MaterialPublishVideo',
  //   parentId: 4003,
  //   path: 'materialPublishVideo',
  //   query: '',
  // },
  // {
  //   component: 'brandManage/material/publishVideo/push',
  //   hidden: true,
  //   id: 400302,
  //   meta: { title: '发视频', menuType: 'Q', icon: '', cache: false, link: false, linkType: '' },
  //   name: 'MaterialPublishVideo_push',
  //   parentId: 4003,
  //   path: 'materialPublishVideo_push',
  //   query: '',
  // },
  // {
  //   component: 'brandManage/material/publishArticle/index',
  //   hidden: true,
  //   id: 400301,
  //   meta: { title: '发文章', menuType: 'V', icon: '', cache: false, link: false, linkType: '' },
  //   name: 'MaterialPublishArticle',
  //   parentId: 4003,
  //   path: 'materialPublishArticle',
  //   query: '',
  // },
  // {
  //   component: 'brandManage/material/publishWorks/index',
  //   hidden: true,
  //   id: 400301,
  //   meta: { title: '发作品', menuType: 'V', icon: '', cache: false, link: false, linkType: '' },
  //   name: 'MaterialpublishWorks',
  //   parentId: 4003,
  //   path: 'materialpublishWorks',
  //   query: '',
  // },
  // {
  //   component: 'brandManage/set/brandInfo/index',
  //   hidden: true,
  //   id: 30020,
  //   meta: { title: '品牌信息 ', menuType: 'C', icon: '', cache: false, link: false, linkType: '' },
  //   name: 'BrandInfo',
  //   parentId: 300,
  //   path: 'brandInfo',
  //   query: '',
  // },
]
const permission = {
  state: {
    routes: [], // 处理过的路由
    menuRoutes: [], // 菜单，处理过的route的path
    // 菜单COPY，List结构，没有children字段，把所有目录、菜单数据整合成List，并增加parentPath字段
    // 通过parentPath查找父目录，用于在antd#menu显示打开菜单目录
    menuCopyRoutes: [],
    // 侧边栏最后一次被激活菜单（一个topNav最后一次只有一个）
    // {"/":{path:"/home"}, "/system": {path:"/system/menu"}, "/product": {path:"/product/contract/info"}, "/risk":{path:"XXX"}}
    lastActiveMenuPath: {},
  },
  mutations: {
    setRoutes: (state, routes) => {
      state.routes = routes
    },
    setMenuRoutes: (state, menuRoutes) => {
      state.menuRoutes = menuRoutes
    },
    setMenuCopyRoutes: (state, menuCopyRoutes) => {
      state.menuCopyRoutes = menuCopyRoutes
    },
    setLastActiveMenuPath: (state, lastActiveMenuPath) => {
      state.lastActiveMenuPath = lastActiveMenuPath
    },
  },
  actions: {
    /**
     * 保存对于topNav菜单的最后一次被激活的子菜单
     * @param commit
     * @param state
     * @param lastActiveMap
     */
    doLastActiveMenuPath({ commit, state }, lastActiveMap) {
      let lastActiveMenuPathJson = state.lastActiveMenuPath
      lastActiveMenuPathJson[lastActiveMap.topNavKey] = lastActiveMenuPathJson[lastActiveMap.topNavKey] || {}
      lastActiveMenuPathJson[lastActiveMap.topNavKey].path = lastActiveMap.lastActiveMenuKey
      if (lastActiveMap.lastActiveMenuQuery) {
        lastActiveMenuPathJson[lastActiveMap.topNavKey].query = lastActiveMap.lastActiveMenuQuery
      }
      commit('setLastActiveMenuPath', lastActiveMenuPathJson)
    },
    /**
     * 生成路由
     * @param commit
     */
    doGenMenuRouteList({ commit }) {
      return new Promise((resolve, reject) => {
        // 调用接口
        getMenuRouteList(store.getters.businessType)
          .then((respData) => {
            getAllMenuRouteList({ moduleType: store.getters.businessType, queryFlag: 'createRole' }).then((res) => {
              // let obj = respData.find((item) => item.path === 'videoPublish/preface')
              // console.log(('obj===>', obj))
              respData = respData.concat(defaultTestRoutes)
              let objs = handleRespData(respData, res)
              respData = objs.concat(respData)
              // console.log(respData)
              let data = listToTree(respData)
              let str = JSON.stringify(data)
              let strRouteData = str
              let strMenuData = str
              let routeData = JSON.parse(strRouteData)
              let menuData = JSON.parse(strMenuData)
              console.log('------', respData)
              // 处理路由结构
              let routes = filterAsyncRouter(routeData)
              commit('setRoutes', routes)

              // 处理成菜单结构
              let menuRoutes = filterAsyncMenu({}, menuData)
              commit('setMenuRoutes', menuRoutes)

              // 转换成[{path:{path, parentPath, id, name,},{path:{path, parentPath, id, name,}]
              let menuCopyRoutes = listToMap(treeToList(menuRoutes), { idField: 'path' })
              commit('setMenuCopyRoutes', menuCopyRoutes)
              console.log('======>routes', routes)
              // console.log('======>menuRoutes', menuRoutes)
              // console.log("======>setMenuCopyRoutes", menuCopyRoutes)
              resolve(routes)
            })
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
  },
}
function handleRespData(respData, allMenusResult) {
  let ids = respData.map((item) => {
    return item.id
  })
  let parentIds = respData.map((item) => {
    return item.parentId
  })
  parentIds = [...new Set(parentIds)]
  parentIds = arrayDifference(parentIds, ids)
  let objs = []
  parentIds.forEach((id) => {
    let obj = getParentNode(allMenusResult, id)
    objs = objs.concat(obj)
  })
  return objs
}
function getParentNode(data, id, arr = []) {
  data.forEach((item) => {
    if (item.id === id) {
      arr.push(item)
      if (item.parentId !== 0) {
        getParentNode(item.parentId, arr)
      }
    }
  })
  return arr
}
function arrayDifference(arr1, arr2) {
  let arr = []
  for (let i = 0; i < arr1.length; i++) {
    if (!arr2.includes(arr1[i])) {
      arr.push(arr1[i])
    }
  }
  return arr
}

/**
 * 过滤处理路由的 component
 * @param routes
 */
function filterAsyncRouter(routes) {
  return routes.map((item) => {
    // ===================================
    // 菜单和页面拆开，菜单配置默认数据补全（S）
    // ===================================
    if (item.meta.menuType === 'C') {
      // 如果是菜单，设置默认值
      if (item.meta.link) {
        item.component = 'Iframe'
        item.path = item.path || ''
        item.query = { src: item.redirect }
        item.redirect = null
      } else {
        // 是菜单，但是没有设置采用ParentView，所以只有一级的就设置成layout，比如首页
        if (!item.component) {
          item.component = 'ParentView'
        }
        item.path = item.path || ''
        // 设置路由组件name
        let viewItem = findFirstBindView(item)
        if (viewItem) {
          // 菜单打开页面，当前route是页面的，所以tabView栏显示也是页面的title，所以需要保持和菜单一直，设置页面title和菜单title一样
          viewItem.meta.title = item.meta.title
        }
      }
    }
    if (item.meta.menuType === 'M') {
      // 目录的路由地址不能空
      item.path = item.path || ''
    }
    if (item.meta.menuType === 'Q') {
      // 目录的路由地址不能空
      item.path = item.path || ''
    }
    // ===================================
    // 菜单和页面拆开，菜单配置默认数据补全（E）
    // ===================================
    if (item.id === 4002 || item.id === 4002101) {
      console.log(item.component)
    }
    if (item.component === 'Layout') {
      item.component = Layout
    } else if (item.component === 'ParentView') {
      // 二级菜单就是一个空的路由
      item.component = ParentView
    } else if (item.component === 'Iframe') {
      item.component = Iframe
    } else {
      // 页面

      item.component = loadView(item.component)
    }

    // 递归处理子节点
    if (item.children && item.children.length) {
      item.children = filterAsyncRouter(item.children)
    } else {
      delete item['children']
    }
    if (item.id === 4002) {
      console.log(item)
    }
    return item
  })
}

/**
 * 查找需要绑定到菜单上的页面，服务端返回的sort排序第一个，作为菜单打开的页面
 *
 * @param path
 * @param children
 * @returns {null|*}
 */
function findFirstBindView({ path, children }) {
  if (children && children.length > 0) {
    return children.find((item) => item.meta.menuType === 'V')
  }
  return null
}

/**
 * 加载view对象
 *
 * @param viewPath
 * @returns {function(): *}
 */
function loadView(viewPath) {
  if (viewPath) {
    if (viewPath.substr(0, 1) !== '/') {
      viewPath = '/' + viewPath
    }
    return () => import(`@/views${viewPath}`)
  }
}

/**
 * 处理路由菜单，拼接path，并且删除路由中不用的字段（component，只保留菜单用的字段）
 * [{name, path, meta, grade, children}]
 *
 * @param parent
 * @param routes
 */
function filterAsyncMenu(parent, routes) {
  let menuRouteList = []
  routes.map((item) => {
    // 隐藏菜单不显示，编辑，新增等路由
    // if (item.hidden) {
    //   return;
    // }

    // 只有目录和菜单才能进入(页面也方进来，但是在菜单render时候，判断hidden和v的不显示
    if (item.meta.menuType !== 'M' && item.meta.menuType !== 'C' && item.meta.menuType !== 'V') {
      return
    }
    item.path = item.path || ''

    // 组件名，组件路径，路由地址，meta，菜单级，节点
    // meta: {"menuType":"M","title":"用户","icon":"usergroup-add","cache":true,"link":false}
    let { name, component, hidden, path, query, redirect, meta, grade, children } = item
    // grade = 1 一级菜单，有了几级菜单，可以针对性设置css样式
    if (parent.grade) {
      grade = parent.grade + 1
    } else {
      grade = 1
    }
    // 新增一个parentPath
    let parentPath = ''
    let currRoute = { name, component, hidden, parentPath, path, query, redirect, meta, grade } // meta是对象copy

    // 如果是外链则不处理，直接用path（肯定是http打头了）
    if (currRoute.meta.link) {
      // 测试blank，db数据暂时没有此配置
      // currRoute.meta.linkType = 'blank';

      if (currRoute.meta.linkType === 'blank') {
        // 用iframe打开
        // 拼接父类路由path + iframe路由path
        currRoute.query = { src: currRoute.redirect }
        currRoute.redirect = null
        currRoute.path = concatPath(parent.path, currRoute.path)
        currRoute.parentPath = parent.path
      } else {
        // 拼接父类路由path + iframe路由path
        currRoute.query = { src: currRoute.redirect }
        currRoute.redirect = null
        currRoute.path = concatPath(parent.path, currRoute.path)
        currRoute.parentPath = parent.path
      }
    } else {
      // 拼接父类路由path + 自己路由path
      currRoute.path = concatPath(parent.path, currRoute.path)
      currRoute.parentPath = parent.path

      // if (currRoute.meta.menuType === 'C') {
      //   // ===================================
      //   // 菜单和页面拆开，菜单的默认配置数据补全（S）
      //   // ===================================
      //   // 设置菜单path=自己path+子节点V的path
      //   let viewItem = findFirstBindView(item);
      //   if (viewItem) {
      //     // 设置path指向sort第一个V节点路由
      //     // update by xuyd 2022-09，菜单不再拼接children第一个节点path，直接redirect到第一个
      //     currRoute.path = concatPath(currRoute.path, viewItem.path);
      //   }
      //   // ===================================
      //   // 菜单和页面拆开，菜单配置默认数据补全（E）
      //   // ===================================
      // }

      // 页面的话不处理【拼接父类路由path + 自己路由path】
      // 目录/菜单的话，可能有子节点，递归处理子节点
      if (children && children.length) {
        // 递归处理子节点
        currRoute.children = filterAsyncMenu(currRoute, children)
      }
    }
    menuRouteList.push(currRoute)
  })
  return menuRouteList
}

export default permission
