<template>
  <div class="backtop-container">
    <a-back-top :target="getAppMainDom">
<!--      <div class="ant-back-top-inner">-->
<!--        UP-->
<!--      </div>-->
    </a-back-top>
  </div>
</template>
<script>

  export default {
    data() {
      return {}
    },
    methods: {
      getAppMainDom() {
        return document.getElementById('refAppMain')
      }
    }
  }
</script>

<style scoped>
  .backtop-container .ant-back-top {
    bottom: 100px;
  }

  .backtop-container .ant-back-top-inner {
    height: 40px;
    width: 40px;
    line-height: 40px;
    border-radius: 4px;
    background-color: #1088e9;
    color: #fff;
    text-align: center;
    font-size: 20px;
  }
</style>
