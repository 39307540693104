import { JSEncrypt } from 'jsencrypt'

let publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDAc1DzX0eUCHd3wgJOve/+n8Wc5QkUKFm4EfDynvg34H/Z3XXLGqxW6pxBBo9a9pUNZlCYsAvaPRT9FClW+ISTsotexUf7Xoro6/+50Tnh10g7DzVtN82IrNjr4h4ysXNQaYQpjLhZCULrV54dz1uow6jjX1bmXfvLZooQyR6/7wIDAQAB'
let privateKey = 'MIICdwIBADANBgkqhkiG9w0BAQEFAASCAmEwggJdAgEAAoGBAMBzUPNfR5QId3fCAk697/6fxZzlCRQoWbgR8PKe+Dfgf9nddcsarFbqnEEGj1r2lQ1mUJiwC9o9FP0UKVb4hJOyi17FR/teiujr/7nROeHXSDsPNW03zYis2OviHjKxc1BphCmMuFkJQutXnh3PW6jDqONfVuZd+8tmihDJHr/vAgMBAAECgYBPS3RQQrLgaLiozHRc81++XyByK+CYwKNu91166ktF4qdrIheVzvCxg0tMc6J/jVgOxFhzAR5xFnfeav85iX4+Ef9I/XRT9qHWlSh+neb3Kb9L9iNZ0HDr0bWfuTITPrDlGwtgL6HquQ33K4gsKCI5x+oMZJ7a3qfckKzP6Qjm4QJBAOLecKdpkMozdZRhBUsIsuRltILF6vRItVt529W4ElZka7VuK+BudS9wLV+m7MLfVZ2j46qt+S676FdXmCBql7ECQQDZKXvv1pwnKrhIsm+iuQJFLhs3bly2t9F2k+vkTHL/safBHe7uTMTLExpBgIS/ojgIfVcqcT5I5/kMgre0jlmfAkBm2pIYVDPgeBQGgTX1Hqx7/+AmqbQrrAsMveJ8skceA8kRKDGcPnfCWgk4JGWSs84M+hBtgRJHrclYT84YZDsBAkEAzRPSCOLAwHQlZ/GqXx8cN7yc0zITuDHLS5HRmN1hVHqvEz5zXvN8fNJjXyxBML++7rV/VYNv8GWw2dGwLoCnvQJBAJqd5h8Ab9HJlp7qU54uzGvpIcr0X643AuasNOrVg/Mq/z2xAKjI+mmj4geUI+/P6Ry8yXsGUCgX3P4weWwZBEE='
const ossPrivateKey = 'MIICeAIBADANBgkqhkiG9w0BAQEFAASCAmIwggJeAgEAAoGBAOCKm1TM0l1HvpCMh5wYOxAmZWrj8qyCPxxhHHbkwaA0YHAwjTkg7HVMwiVPS5cSyO6NRbti59HMZ2V8kc7iq0NF8duSCQZ/5Q8jZ8t030039wDn5WdXWpJTMv2hpA/0Qi8k0OXyrwF3pgKhdq1Bu7w+UAp8A8XE+4YXWIYHTC1BAgMBAAECgYEArqK+uo5ukkQJb20Mz5d84EuslxzgkdDe+tiAhevWuB3kX30zaRo1LencGZ55gO8IPZi8gkgjWmWqiSEAWjKq1Tst2hdK8JaCl3j8AnPtrW+3OvUM5rzQfHjnsW7hxcYwCQvPDdDWk64MC7kilcISYWWv2OSsx5XfzJEv+Pz9BBECQQD8p3FjQxRMF9lqdtT3KOZhSzOJLHjb3RCW/gS5Jsa5GVcHTfU87mTR/OdJKfqja/H5Prxq0J9lizqdeIfMk4ftAkEA44Pa92fKYxPAHQ/IlL6v27H7Q40zuTcYbV5UoWpc9uzhPKKb2djMKA9nz58qyFbGJWEweOKbvU57PBiQsx6oJQJAc/hf4ZIuxObFk0rMYk7RwKMGGtbg+vqz3LrobBxSZzAsdFviT3+Vo5WUjE7KjyzDOwcC8XylTbTkyNdEbjDh2QJBAJYtA80I4ouRjt7f+ztLvxLaIYuOWdTo7CPM/zno1mJbK7V1dEBQnTnYKWLfokLJHFOyGmfA1KIZerjJUkIZe00CQQD1F6qxgU7pxv1bHKY2rl2IF+HOZlQ7OB2NqtunB9aMHIF2jsePOW5i3D/pMmjPZ82MhMClMQeKixAFZEHAU0NZ'
/**
 * 加密处理
 * @param text
 * @returns {string | false}
 */
export function encrypt(text) {
  let str = text
  try {
    let jsEncrypt = new JSEncrypt()
    jsEncrypt.setPublicKey(publicKey)
    str = jsEncrypt.encrypt(text)
    console.log('加密后串' + str)
  } catch (e) {
    console.log('加密失败', e)
  }
  return str + ''
}

/**
 * 解密处理
 * @param text
 * @returns {string | false}
 */
export function decrypt(text) {
  let str = text
  try {
    let jsEncrypt = new JSEncrypt()
    jsEncrypt.setPrivateKey(privateKey)
    str = jsEncrypt.decrypt(text)
    console.log('解密后串' + str)
  } catch (e) {
    console.log('解密失败', e)
  }
  return str + ''
}

/**
 * 解密处理
 * @param text
 * @returns {string | false}
 */
export function decryptOSS(text) {
  let str = text
  try {
    const jsEncrypt = new JSEncrypt()
    jsEncrypt.setPrivateKey(ossPrivateKey)
    str = jsEncrypt.decrypt(text)
    console.log('解密后串' + str)
  } catch (e) {
    console.log('解密失败', e)
  }
  return str + ''
}
