<template>
  <div class="logo" :style="{'background-color': app.theme}">
    <template v-if="topNav">
      <img :src="logoPlatform" class="openImg" />
    </template>
    <a-dropdown :trigger="['click']">
      <a-icon v-if="topNav" type="down" class="dropdown" />
      <template v-else>
        <img v-if="sidebar.collapsed" :src="logo" class="closeImg" />
        <img v-else :src="logoPlatform" class="openImg" />
      </template>
      <a-menu slot="overlay">
        <a-menu-item v-for="(item, index) in businessList" :key="index">
          <div @click="handleSelectPlatform(item)">
            <span style="width: 20px;display: inline-block;">
              <a-icon type="check" v-show="item.businessUserId == businessUserId" />
            </span>
            <span>{{item.shopName + `(${dict.label.platformType[item.businessType]})`}}</span>
          </div>
        </a-menu-item>
      </a-menu>
    </a-dropdown>
  </div>
</template>
<script>
import logoImg from '@/assets/img/logo.png'
import logo_platform1 from '@/assets/img/logo-platform1.png'
import logo_platform2 from '@/assets/img/logo-platform2.png'
import logo_platform3 from '@/assets/img/logo-platform3.png'
import logo_platform5 from '@/assets/img/logo-platform5.jpg'
import { mapGetters, mapState } from 'vuex'
import { setBusinessUser } from '@/utils/auth'

export default {
  name: 'Logo',
  dictTypes: [
    { dictType: 'businessType', dataType: String }, // 平台类型
    { dictType: 'platformType', dataType: String }, // 平台类型
  ],
  data() {
    return {
      logo: logoImg,
      logo_platform1: logo_platform1,
      logo_platform2: logo_platform2,
      logo_platform3: logo_platform3,
      logo_platform5: logo_platform5,
    }
  },
  computed: {
    ...mapGetters(['businessList', 'businessType', 'businessUserId']),
    ...mapState({
      sidebar: (state) => state.app.sidebar,
      topNav: (state) => state.app.topNav,
    }),
    app() {
      return this.$store.state.app
    },
    logoPlatform() {
      return this['logo_platform' + this.businessType]
      // return 'logo_platform' + this.businessType
    },
  },
  mounted() {
    console.log(this.dict, 'dict')
  },
  methods: {
    /**
     * 切换平台
     * @param item
     */
    handleSelectPlatform(item) {
      if (!item.businessType) {
        this.$modal.msgError('选择您需切换的平台')
      } else {
        console.log('切换平台', item)
        // 选择好平台，保存businessUserId到cookie中，用于路由跳转判断
        setBusinessUser({ businessUserId: item.businessUserId, businessType: item.businessType })
        window.location.href = '/index'
      }
    },
  },
}
</script>

<style scoped lang="scss">
.logo {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .openImg {
    /*width: 218px;*/
    width: 100%;
    height: 46px;
    /*width: 160px; height: 36px*/
  }

  .closeImg {
    width: 46px;
    height: 46px;
    /*width: 36px; height: 36px*/
  }

  .dropdown {
    cursor: pointer;
  }
}

.hidden {
  display: none;
}
</style>
